
const getDefaultState = () => {
    
    return {

        current_guided_task: { success: false },
        
    }
}

const store_guided_task = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

        getCurrentGuidedTask (state) {
            return state.current_guided_task
        },

        getCurrentGuidedTaskByKey (state, data) {
            return state.current_guided_task[data]
        },


    },

    mutations: {

        updateCurrentGuidedTask (state, data) {

            state.current_guided_task = data

        },

        updateCurrentGuidedTaskByKey(state, data) {
            state.current_guided_task[data.key] = data.val
        },



        // RESET LESSIONS_API
        resetState (state) {
            Object.assign(state, getDefaultState())
        }

    },

    actions: {

        UPDATE_CURRENT_GUIDED_TASK({commit}, data) {
            commit('updateCurrentGuidedTask', data)

        },

        UPDATE_CURRENT_GUIDED_TASK_BY_KEY({commit}, data) {
            commit('updateCurrentGuidedTaskByKey', data)

        },


        RESET_STATE ({ commit }) {
            commit('resetState')
        },


    }

}

export default store_guided_task