<template>

<div>

    <v-list dense>

        <v-list-item-group v-model="chats_group_model" color="primary">

            <v-list-item 
                :to="homeClicked"
                exact
                v-if="authenticated"
            >
                <v-list-item-icon>
                        <v-icon>fas fa-home</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Startseite</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-subheader v-if="authenticated && usergroup == 'Supervisor'">Risk-Assessment</v-subheader>

            <v-list-item  
                to="/supervisor/acute/null" 
                exact
                v-if="authenticated && usergroup == 'Supervisor'"
            >
                <v-list-item-icon>

                    <v-icon>fas fa-chart-area</v-icon>

                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Acute-2007-SR</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item  
                to="/supervisor/cmc/null" 
                exact
                v-if="authenticated && usergroup == 'Supervisor'"
            >
                <v-list-item-icon>

                    <v-icon>fas fa-chart-line</v-icon>

                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>CMC</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item  
                to="/supervisor/cte/null" 
                exact
                v-if="authenticated && usergroup == 'Supervisor'"
            >
                <v-list-item-icon>

                    <v-icon>fas fa-chart-bar</v-icon>

                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>CTE</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item  
                to="/supervisor/flashlight-pre/null" 
                exact
                v-if="authenticated && usergroup == 'Supervisor'"
            >
                <v-list-item-icon>

                    <v-icon>fas fa-chart-pie</v-icon>

                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Blitzlicht (Prä)</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item  
                to="/supervisor/flashlight-post/null" 
                exact
                v-if="authenticated && usergroup == 'Supervisor'"
            >
                <v-list-item-icon>

                    <v-icon>fas fa-chart-pie</v-icon>

                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Blitzlicht (Post)</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-subheader v-if="authenticated && usergroup == 'Supervisor'">Kritische Ereignisse</v-subheader>

            <v-list-item  
                to="/supervisor/critical-event/null" 
                exact
                v-if="authenticated && usergroup == 'Supervisor'"
            >
                <v-list-item-icon>
                    <v-badge
                        :color="nr_new_critical_events > 0 ? 'error':'success'"
                        dot
                        outlined
                    >
                        <v-icon>fas fa-user-md</v-icon>
                    </v-badge> 
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Kritische Ereignisse</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-subheader v-if="authenticated && usergroup == 'Supervisor'">Aufwandsentschädigung</v-subheader>

            <v-list-item  
                to="/supervisor/payments/null" 
                exact
                v-if="authenticated && usergroup == 'Supervisor'"
            >
                <v-list-item-icon>
                    <v-badge
                        :color="open_payments == true ? 'error':'success'"
                        dot
                        outlined
                    >
                        <v-icon>fas fa-hand-holding-usd</v-icon>
                    </v-badge> 
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Offene Auszahlungen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>


            <v-subheader v-if="authenticated && usergroup == 'Supervisor'">Fragebögen</v-subheader>


            <v-list-item  
                :to="{name: 'supervisor-assessment', params: { search_prop: 'null'}}" 
                exact
                v-if="authenticated && usergroup == 'Supervisor'"
            >
                <v-list-item-icon>
                    <v-badge
                        :color="nr_new_polls > 0 ? 'error':'success'"
                        dot
                        outlined
                    >
                        <v-icon>fas fa-poll</v-icon>
                    </v-badge> 
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Fragebögen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-subheader v-if="authenticated && usergroup == 'Client'">Online-Programm</v-subheader>

            <v-list-item 
                @click="got_to_introduction"
                exact
                v-if="authenticated && usergroup == 'Client'"
            >
                <v-list-item-icon>
                    <v-icon>far fa-compass</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Einführung</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item 
                @click="go_to_intervention"
                exact
                :disabled="!introduction_finished"
                v-if="authenticated && usergroup == 'Client'"
            >
                <v-list-item-icon>
                        <v-icon :color="!introduction_finished ? 'grey' : ''">far fa-play-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-if="intervention_started && $store.getters['store_client/get_client_progress'].current_module_nr < 7">Weitermachen</v-list-item-title>
                    <v-list-item-title v-if="!intervention_started">Beginnen</v-list-item-title>
                    <v-list-item-title v-if="intervention_started && $store.getters['store_client/get_client_progress'].current_module_nr == 7 && $store.getters['store_client/get_client'].status != 5">Abschließen</v-list-item-title>
                    <v-list-item-title v-if="intervention_started && $store.getters['store_client/get_client_progress'].current_module_nr == 7 && $store.getters['store_client/get_client'].status == 5">Ansehen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item 
                :to="overviewClicked" 
                exact
                v-if="authenticated && usergroup == 'Client'"
                :disabled="!introduction_finished"
            >
                <v-list-item-icon>
                        <v-icon :color="!introduction_finished ? 'grey' : ''">fas fa-grip-horizontal</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Überblick</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-subheader v-if="authenticated">Chats</v-subheader>
            
            <v-list-item 
                :to="newCommentClicked" 
                exact
            >
                <v-list-item-icon>
                        <v-icon>far fa-comment</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Neuer Chat</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                :to="routerLinkToMessages" 
                exact
            >
                <v-list-item-icon>
                    <v-badge
                        :color="nr_new_messages > 0 ? 'error':'success'"
                        dot
                        outlined
                    >
                        <v-icon>far fa-comments</v-icon>
                    </v-badge> 
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Alle Chats</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                :to="routerLinkToArchivedMessages" 
                exact
            >
                <v-list-item-icon>
                    <v-icon>fas fa-archive</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Archivierte Chats</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-subheader v-if="authenticated && usergroup == 'Coach'">Guided Tasks</v-subheader>

            <v-list-item  
                :to="routerLinkToTasks" 
                exact
                v-if="authenticated && usergroup == 'Coach'"
            >
                <v-list-item-icon>
                    <v-badge
                        :color="nr_new_tasks > 0 ? 'error':'success'"
                        dot
                        outlined
                    >
                        <v-icon>fas fa-tasks</v-icon>
                    </v-badge> 
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Offene Übungen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item  
                :to="routerLinkToClosedTasks" 
                exact
                v-if="authenticated && usergroup == 'Coach'"
            >
                <v-list-item-icon>
                    <v-icon>far fa-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Abgeschlossene Übungen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-subheader v-if="authenticated && usergroup == 'Client'">Übungen</v-subheader>

            <v-list-item  
                :to="routerLinkToTasks" 
                exact
                v-if="authenticated && usergroup == 'Client'"
            >
                <v-list-item-icon>
                    <v-badge
                        :color="nr_new_tasks > 0 ? 'error':'success'"
                        dot
                        outlined
                    >
                        <v-icon>fas fa-tasks</v-icon>
                    </v-badge> 
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Übungen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item  
                :to="routerLinkToPolls" 
                exact
                v-if="authenticated && usergroup == 'Client'"
            >
                <v-list-item-icon>
                    <v-badge
                        :color="nr_new_polls > 0 ? 'error':'success'"
                        dot
                        outlined
                    >
                        <v-icon>fas fa-poll</v-icon>
                    </v-badge> 
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Fragebögen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-subheader v-if="authenticated && usergroup == 'Client'" class="mb-0">Belohnungen</v-subheader>

            <v-list-item  
                :to="routerLinkToAwards" 
                exact
                v-if="authenticated && usergroup == 'Client'"
            >
                <v-list-item-icon>
                    <v-icon>fas fa-award</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Auszeichnungen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item  
                :to="routerLinkToBank" 
                exact
                v-if="authenticated && usergroup == 'Client'"
            >
                <v-list-item-icon>
                        <v-icon>fas fa-hand-holding-usd</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Konto</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-subheader v-if="authenticated && usergroup == 'Client'">Hilfe</v-subheader>

            <v-list-item  
                :to="medkitClicked" 
                v-if="authenticated && usergroup == 'Client'"
            >
                <v-list-item-icon>
                        <v-icon color="error">fas fa-medkit</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Mein Notfallkoffer</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item 
                :to="routerLinkToHelp"
                exact
                v-if="authenticated && usergroup == 'Client'"
            >
                <v-list-item-icon>
                    <v-icon>far fa-question-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Hilfe</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-subheader v-if="authenticated && usergroup == 'Supervisor'">Hilfe</v-subheader>

            <v-list-item 
                to="/supervisor/help"
                exact
                v-if="authenticated && usergroup == 'Supervisor'"
            >
                <v-list-item-icon>
                    <v-icon>far fa-question-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Hilfe</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

        </v-list-item-group>

    </v-list>

</div>

</template>

<script>
export default {
    name: 'main-menu',

    data: () => ({

        fav: true,
        intervention_group_model: false,
        chats_group_model: false,
        
    }),

    computed: {

        open_payments() {
            return this.$store.getters['store_supervisor/get_open_payments']
        },

        drawer: {

            set(val) {

                this.$store.dispatch('store_menu/UPDATE_DRAWER', val)

            },

            get() {

                return this.$store.getters['store_menu/get_drawer']
            }

        },

        authenticated() {
            return this.$store.getters['store_auth/get_is_authenticated']
        },

        intervention_started() {
            return this.$store.getters['store_client/get_client_progress'].intervention_started
        },

        introduction_finished() {
            return this.$store.getters['store_client/get_client_progress'].introduction_finished
        },

        get_usergroup() {

            if (this.usergroup == 'Client') {
                return 'Klient'
            } else if (this.usergroup == 'Coach') {
                return 'Coach'
            } else {
                return 'Bewährungshelfer'
            }
        },


        nr_new_messages() {

            if (this.usergroup == 'Client') {

                return this.$store.getters['store_client/get_client_messages'].unread_message_count

            } else if (this.usergroup == 'Coach') {

                return this.$store.getters['store_coach/get_coach_messages'].unread_message_count

            } else {

                return this.$store.getters['store_supervisor/get_supervisor_messages'].unread_message_count

            }
            
        },

        new_risk_data() {

            return this.$store.getters['store_supervisor/get_supervisor_risk_assessment'].new_risk_data_since_last_login

        },

        nr_new_tasks() {

            if (this.usergroup == 'Client') {

                return this.$store.getters['store_client/get_client_tasks'].unfinished_tasks_count_all

            } else if (this.usergroup == 'Coach') {

                return this.$store.getters['store_coach/get_coach_tasks'].unfinished_tasks_count

            } else {

                return 0

            }

        },

        nr_new_critical_events() {

            if (this.usergroup == 'Supervisor') {

                return this.$store.getters['store_supervisor/get_supervisor_critical_events'].unread_critical_event_count

            } else {

                return 0

            }

        },

        nr_new_polls() {

            if (this.usergroup == 'Client') {

                return this.$store.getters['store_client/get_client_questionnaires'].unfinished_questionnaires_count

            } else if (this.usergroup == 'Supervisor') {

                return this.$store.getters['store_supervisor/get_supervisor_questionnaires'].unfinished_questionnaires_count

            } else {

                return 0

            }

        },


        nr_new_awards() {

            return this.$store.getters['store_client/get_client_award'].award_not_equipped

        },


        current_coins() {

            return this.$store.getters['store_client/get_client_reward'].current_coins
            
        },

        username() {
            return this.$store.getters['store_auth/get_user_username']
        },

        usergroup() {
            return this.$store.getters['store_auth/get_user_group']
        },

        homeClicked() {

            if (this.authenticated) {
                if (this.usergroup == 'Client') {
                    return '/client'
                } else if (this.usergroup == 'Coach') {
                    return '/coach'
                } else {
                    return '/supervisor'
                }
            } else {
                return '/login'
            }

        },

        overviewClicked() {
            return '/client/overview'
        },

        newCommentClicked() {
            if (this.usergroup == 'Client') {
                return { name: 'client-new-messages' }
            } else if (this.usergroup == 'Coach') {
                return { name: 'coach-new-messages' }
            } else if (this.usergroup == 'Supervisor') {
                return { name: 'supervisor-new-messages' }
            } else {
                return '/'
            }
        },

        routerLinkToPolls () {

            return '/client/polls'

        },

        // link to messages
        routerLinkToMessages () {

            if (this.usergroup == 'Client') {
                return { name: 'client-messages', params: { search_prop: 'null'} }
            } else if (this.usergroup == 'Coach') {
                return { name: 'coach-messages', params: { search_prop: 'null'} }
            } else if (this.usergroup == 'Supervisor') {
                return { name: 'supervisor-messages', params: { search_prop: 'null'} }
            } else {
                return '/'
            }
            
        },

        routerLinkToArchivedMessages() {

            if (this.usergroup == 'Client') {
                return { name: 'client-messages-archived', params: { search_prop: 'null'} }
            } else if (this.usergroup == 'Coach') {
                return { name: 'coach-messages-archived', params: { search_prop: 'null'} }
            } else if (this.usergroup == 'Supervisor') {
                return { name: 'supervisor-messages-archived', params: { search_prop: 'null'} }
            } else {
                return '/'
            }

        },

        // link to bank
        routerLinkToBank () {
            return '/client/bank'
        },

        // link to tasks
        routerLinkToTasks () {

            if (this.usergroup == 'Client') {
                return '/client/tasks'
            } else if (this.usergroup == 'Coach') {
                return { name: 'coach-tasks', params: { search_prop: 'null'} }
            } else {
                return '/'
            }
            
        },

        routerLinkToClosedTasks() {
            return { name: 'coach-tasks-closed', params: { search_prop: 'null'} }
        },

        // link to awards
        routerLinkToAwards () {
            return '/client/awards'
        },

        // link to help
        routerLinkToHelp () {

            if (this.usergroup == 'Client') {
                return '/client/help'
            } else if (this.usergroup == 'Coach') {
                return '/coach/help'
            } else {
                return 'supervisor/help'
            }
        },

        medkitClicked() {
            return '/client/emergency'
        },
        
    },

    methods: {

        got_to_introduction() {

            // set page allowed to true
            console.log('UPDATE_PAGE_ALLOWED: main_menu got_to_introduction')
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

            if (this.$store.getters['store_client/get_client_progress'].introduction_finished) {
                this.$router.push({ name: 'client-introduction', params: { rank: 1 } })
            } else {
                this.$router.push({ name: 'client-introduction', params: { rank: this.$store.getters['store_client/get_client_progress'].current_lession_nr } })
            }

        },

        go_to_intervention() {

            // set page allowed to true
            console.log('UPDATE_PAGE_ALLOWED: main_menu startClicked()')
            this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

            if (this.intervention_started && this.$store.getters['store_client/get_client_progress'].current_module_nr < 7) {

                this.$router.push({ name: 'client-intervention', params: { module: this.$store.getters['store_client/get_client_progress'].current_module_nr, session: this.$store.getters['store_client/get_client_progress'].current_session_nr, lection: this.$store.getters['store_client/get_client_progress'].current_lession_nr } })
            
            } else if (this.$store.getters['store_client/get_client_progress'].current_module_nr == 7 && this.$store.getters['store_client/get_client'].status != 5) {
                
                this.$router.push({ name: 'client-final'})

            } else if (this.$store.getters['store_client/get_client_progress'].current_module_nr == 7 && this.$store.getters['store_client/get_client'].status == 5) {

                // set page allowed to true
                console.log('UPDATE_PAGE_ALLOWED: client_home')
                this.$store.dispatch('store_client/UPDATE_PAGE_ALLOWED', true)

                this.$router.push({ name: 'client-intervention', params: { module: 1, session: 1, lection: 1 } })

            } else {

                this.$router.push({ name: 'client-intervention', params: { module: 1, session: 1, lection: 1 } })

            }

        }

        
    }
    
}
</script>


<style scoped>

.wrapper {
  position:relative;
}

.custom_badge {
    position: absolute;
    top: 2px;
    left:-12px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    text-align: center;
    background: #FF5252;
    color: #fefefe;
}

</style>


