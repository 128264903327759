"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '@/store/store'

//import authentication from '@/_auth/auth'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.defaults.baseURL = 'https://mytabu.umg.eu/backend/' //  development: http://localhost:8000/; ePsM: http://134.76.18.114:80/; myTabu: http://134.76.18.240:80/ 

// !!!! PRODUCTION: https://mytabu.umg.eu/backend/

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const token = Vue.prototype.$session.get('access_token')

if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

const _axios = axios.create(config);

axios.interceptors.request.use(

  function(config) {
    
    // Do something before request is sent
    store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', true)

    return config;

  },

  function(error) {

    // Do something with request error
    return Promise.reject(error);

  }

);

// Add a response interceptor
axios.interceptors.response.use(

  function(response) {

    store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

    return response;

  },

  function(error) {

    // Do something with response error
    return Promise.reject(error);

  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
