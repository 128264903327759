const getDefaultState = () => {
    
    return {

        // all not archived threads
        user_threads: [],

        // all deleted threads
        user_threads_deleted: [],
        nr_user_threads_deleted: 0,

        // all unread threads
        user_threads_unread: [],
        nr_user_threads_unread: 0,

        // all already read threads
        user_threads_read: [],
        nr_user_threads_read: 0,

        // current choosen thread
        current_thread_id: null,
        current_thread_type: null,
        current_thread_is_deleted: false,

        current_thread: {
            thread: []
        },

        is_refreshing: false,

    }
}

const store_messages = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

        getUserThreads: state => {
            return state.user_threads
        },

        getUserThreadsUnread: state => {
            return state.user_threads_unread
        },
    
        getNrUserThreadsUnread: state => {
            return state.user_threads_unread.length
        },
    
        getUserThreadsRead: state => {
            return state.user_threads_read
        },
    
        getNrUserThreadsRead: state => {
            return state.user_threads_read.length
        },
    
        getUserThreadsDeleted: state => {
            return state.user_threads_deleted
        },
    
        getNrUserThreadsDeleted: state => {
            return state.user_threads_deleted.length
        },
    
        getCurrentThread: state => {
            return state.current_thread
        },
    
        getCurrentThreadId: state => {
            return state.current_thread_id
        },
    
        getCurrentThreadType: state => {
            return state.current_thread_type
        },
    
        getCurrentThreadDeleted: state => {
            return state.current_thread_is_deleted
        },
    
        getIsRefreshing: state => {
            return state.is_refreshing
        },

    },

    mutations: {

        updateUserThreads(state, data) {
            state.user_threads = data
        },

        updateUserThreadsUnread(state, data) {
            state.user_threads_unread = data
        },
    
        updateUserThreadsRead(state, data) {
            state.user_threads_read = data
        },
    
        updateUserThreadsDeleted(state, data) {
            state.user_threads_deleted = data
        },
    
        updateCurrentThread(state, data) {
            state.current_thread.thread = data
        },
    
        addMessageToCurrentThread(state, data) {
            state.current_thread.thread.all_messages.push(data)
        },
    
        updateCurrentThreadId(state, data) {
            state.current_thread_id = data
        },
    
        updateCurrentThreadType(state, data) {
            state.current_thread_type = data
        },
    
        updateCurrentThreadDeleted(state, data) {
            state.current_thread_is_deleted = data
        },
    
        updateIsRefreshing(state, data) {
            state.is_refreshing = data
        },


      // RESET
      resetState (state) {
        Object.assign(state, getDefaultState())
      }

    },

    actions: {

        UPDATE_USER_THREADS({commit}, data) {
            commit('updateUserThreads', data)
        },

        UPDATE_USER_THREADS_UNREAD({commit}, data) {
            commit('updateUserThreadsUnread', data)
        },
    
        UPDATE_USER_THREADS_READ({commit}, data) {
            commit('updateUserThreadsRead', data)
        },
    
        UPDATE_USER_THREADS_DELETED({commit}, data) {
            commit('updateUserThreadsDeleted', data)
        },
    
        UPDATE_CURRENT_THREAD({commit}, data) {
            commit('updateCurrentThread', data)
        },
    
        ADD_MESSAGE_TO_CURRENT_THREAD({commit}, data) {
            commit('addMessageToCurrentThread', data)
        },
    
        UPDATE_CURRENT_THREAD_ID({commit}, data) {
            commit('updateCurrentThreadId', data)
        },
    
        UPDATE_CURRENT_THREAD_TYPE({commit}, data) {
            commit('updateCurrentThreadType', data)
        },
    
        UPDATE_CURRENT_THREAD_DELETED({commit}, data) {
            commit('updateCurrentThreadDeleted', data)
        },
    
        UPDATE_IS_REFRESHING({commit}, data) {
            commit('updateIsRefreshing', data)
        },


      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_messages