const getDefaultState = () => {
    
    return {

        show_emergency_dialog: false,
        show_help_dialog: false,
        show_message_dialog: false,

        show_client_snackbar: false,
        client_snackbar_text: '',

        show_global_snackbar: false,
        global_snackbar_text: '',

        show_cookies_dialog: false,

        show_award_dialog: {
          
          show: false,
          image: 'HerosuitTN.png'

        },

        finish_dialog_has_been_shown: false,

    }

}

const store_dialogs = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

      get_show_emergency_dialog: state => state.show_emergency_dialog,
      get_show_message_dialog: state => state.show_message_dialog,
      get_show_help_dialog: state => state.show_help_dialog,

      get_show_client_snackbar: state => state.show_client_snackbar,
      get_client_snackbar_text: state => state.client_snackbar_text,
      get_show_global_snackbar: state => state.show_global_snackbar,
      get_global_snackbar_text: state => state.global_snackbar_text,

      get_show_cookies_dialog: state => state.show_cookies_dialog,

      get_show_award_dialog: state => state.show_award_dialog,

      get_finish_dialog_has_been_shown: state => state.finish_dialog_has_been_shown,

        
    },

    mutations: {

      update_show_emergency_dialog (state, data) {
        state.show_emergency_dialog = data
      },

      update_show_cookies_dialog (state, data) {
        state.show_cookies_dialog = data
      },

      update_finish_dialog_has_been_shown (state, data) {
        state.finish_dialog_has_been_shown = data
      },

      update_show_award_dialog (state, data) {
        state.show_award_dialog = data
      },

      update_show_message_dialog (state, data) {
        state.show_message_dialog = data
      },

      update_show_help_dialog (state, data) {
        state.show_help_dialog = data
      },

      update_show_client_snackbar (state, data) {
        state.show_client_snackbar = data
      },

      update_client_snackbar_text (state, data) {
        state.client_snackbar_text = data
      },

      update_show_global_snackbar (state, data) {
        state.show_global_snackbar = data
      },

      update_global_snackbar_text (state, data) {
        state.global_snackbar_text = data
      },


      // RESET
      resetState (state) {
        Object.assign(state, getDefaultState())
      }

    },

    actions: {

      UPDATE_SHOW_EMERGENCY_DIALOG({commit}, data) {
        commit('update_show_emergency_dialog', data)
      },

      UPDATE_FINISH_DIALOG_HAS_BEEN_SHOWN({commit}, data) {
        commit('update_finish_dialog_has_been_shown', data)
      },

      UPDATE_SHOW_COOKIES_DIALOG({commit}, data) {
        commit('update_show_cookies_dialog', data)
      },

      UPDATE_SHOW_AWARD_DIALOG({commit}, data) {
        commit('update_show_award_dialog', data)
      },

      UPDATE_SHOW_MESSAGE_DIALOG({commit}, data) {
        commit('update_show_message_dialog', data)
      },

      UPDATE_SHOW_HELP_DIALOG({commit}, data) {
        commit('update_show_help_dialog', data)
      },

      UPDATE_SHOW_CLIENT_SNACKBAR({commit}, data) {
        commit('update_show_client_snackbar', data)
      },

      UPDATE_CLIENT_SNACKBAR_TEXT({commit}, data) {
        commit('update_client_snackbar_text', data)
      },

      UPDATE_SHOW_GLOBAL_SNACKBAR({commit}, data) {
        commit('update_show_global_snackbar', data)
      },

      UPDATE_GLOBAL_SNACKBAR_TEXT({commit}, data) {
        commit('update_global_snackbar_text', data)
      },

      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_dialogs