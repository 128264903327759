import _ from 'lodash'

const getDefaultState = () => {
    
    return {

      lection_wagtail_content: {},
      lection_info: {},
      saved_lection_data: {},
      saved_lection_task_data: {},

      saved_lection_quiz_data: {},

      saved_lection_guided_task_data: {}

    }
}

const store_client = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

      get_lection_wagtail_content: state => state.lection_wagtail_content,
      get_lection_info: state => state.lection_info,

      get_saved_lection_data: state => state.saved_lection_data,

      get_saved_lection_task_data: state => state.saved_lection_task_data,
      get_saved_lection_task_data_by_identifier: state => (identifier) => {

        return _.get(state.saved_lection_task_data, identifier)

      },

      get_saved_lection_quiz_data: state => state.saved_lection_quiz_data,
      get_saved_lection_quiz_data_by_identifier: state => (taskID, identifier) => {

        return _.get(state.saved_lection_task_data[taskID], identifier)

      },

      get_saved_lection_guided_task_data: state => state.saved_lection_guided_task_data,
      get_saved_lection_guided_task_data_by_identifier: state => (identifier) => {

        return _.get(state.saved_lection_guided_task_data, identifier)

      },

    },

    mutations: {

      update_lection_wagtail_content (state, data) {
        state.lection_wagtail_content = data
      },

      update_lection_info (state, data) {
        state.lection_info = data
      },

      update_saved_lection_data (state, data) {
        state.saved_lection_data = data
      },

      update_saved_lection_task_data (state, data) {
        state.saved_lection_task_data = data
      },

      update_saved_lection_data_finished(state, data) {
        state.saved_lection_data.data[data.id].finished = data.data
      },

      update_saved_lection_data_btn_disabled(state, data) {
          state.saved_lection_data.data[data.id].btn_disabled = data.data
      },

      update_saved_lection_data_lection_finished(state, data) {
          state.saved_lection_data.lession_finished = data.data
      },

      update_next_lection_data_show(state, data) {
          state.saved_lection_data.data[data.id].show = data.data
      },

      // task data
      add_saved_lection_task_data_property_and_data (state, data) {
          state.saved_lection_task_data[data.identifier] = _.set(state.saved_lection_task_data[data.identifier], data.property_name, data.data)
      },

      define_saved_lection_task_data_new_data_identifier(state, data) {
          state.saved_lection_task_data = _.set(state.saved_lection_task_data, data.taskID, {})
      },

      // guided task data
      add_saved_lection_guided_task_data_property_and_data (state, data) {
          state.saved_lection_guided_task_data[data.identifier] = _.set(state.saved_lection_guided_task_data[data.identifier], data.property_name, data.data)
      },

      define_saved_lection_guided_task_data_new_data_identifier(state, data) {
          state.saved_lection_guided_task_data = _.set(state.saved_lection_guided_task_data, data.taskID, {})
      },


      // quiz data
      update_saved_lection_quiz_data(state, data) {
        state.saved_lection_quiz_data = data
      },

      define_saved_lection_quiz_data_new_data_identifier_taskID(state, data) {
          state.saved_lection_task_data = _.set(state.saved_lection_task_data, data.taskID, {})
      },

      define_saved_lection_quiz_data_new_data_identifier_id(state, data) {
        state.saved_lection_task_data[data.taskID] = _.set(state.saved_lection_task_data[data.taskID], data.id, {})
    },

      add_saved_lection_quiz_data_property_and_data (state, data) {
          state.saved_lection_task_data[data.taskID][data.identifier] = _.set(state.saved_lection_task_data[data.taskID][data.identifier], data.property_name, data.data)
      },

      // RESET
      resetState (state) {
        Object.assign(state, getDefaultState())
      }

    },

    actions: {

      UPDATE_LECTION_WAGTAIL_CONTENT({commit}, data) {
        commit('update_lection_wagtail_content', data)
      },

      UPDATE_LECTION_INFO({commit}, data) {
        commit('update_lection_info', data)
      },

      UPDATE_SAVED_LECTION_DATA({commit}, data) {
        commit('update_saved_lection_data', data)
      },

      UPDATE_SAVED_LECTION_TASK_DATA({commit}, data) {
        commit('update_saved_lection_task_data', data)
      },

      UPDATE_SAVED_LECTION_DATA_FINISHED({commit}, data){
          commit('update_saved_lection_data_finished', data)
      },

      UPDATE_SAVED_LECTION_DATA_BTN_DISABLED({commit}, data){
          commit('update_saved_lection_data_btn_disabled', data)
      },

      UPDATE_SAVED_LECTION_DATA_LECTION_FINISHED({commit}, data){
          commit('update_saved_lection_data_lection_finished', data)
      },

      UPDATE_NEXT_LESSION_DATA_SHOW({commit}, data){
          commit('update_next_lection_data_show', data)
      },

      // TASK
      ADD_SAVED_LECTION_TASK_DATA_PROPERTY_AND_DATA({commit}, data){
          commit('add_saved_lection_task_data_property_and_data', data)
      },

      DEFINE_SAVED_LECTION_TASK_DATA_NEW_DATA_IDENTIFIER({commit}, data){
          commit('define_saved_lection_task_data_new_data_identifier', data)
      },

      // GUIDED TASK
      ADD_SAVED_LECTION_GUIDED_TASK_DATA_PROPERTY_AND_DATA({commit}, data){
          commit('add_saved_lection_guided_task_data_property_and_data', data)
      },

      DEFINE_SAVED_LECTION_GUIDED_TASK_DATA_NEW_DATA_IDENTIFIER({commit}, data){
          commit('define_saved_lection_guided_task_data_new_data_identifier', data)
      },

      // QUIZ
      UPDATE_SAVED_LECTION_QUIZ_DATA({commit}, data){
          commit('update_saved_lection_quiz_data', data)
      },

      DEFINE_SAVED_LECTION_QUIZ_DATA_NEW_DATA_IDENTIFIER_ID({commit}, data){
          commit('define_saved_lection_quiz_data_new_data_identifier_id', data)
      },

      DEFINE_SAVED_LECTION_QUIZ_DATA_NEW_DATA_IDENTIFIER_TASKID({commit}, data){
          commit('define_saved_lection_quiz_data_new_data_identifier_taskID', data)
      },

      ADD_SAVED_LECTION_QUIZ_DATA_PROPERTY_AND_DATA({commit}, data){
          commit('add_saved_lection_quiz_data_property_and_data', data)
      },



      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_client