import Vue from 'vue'

const getDefaultState = () => {
    
    return {

        current_page_finished_blocks: [],
        page_finished: false

    }

}

const store_introduction = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

      get_current_page_finished_blocks: state => state.current_page_finished_blocks,
      get_page_finished: state => state.page_finished,
        
    },

    mutations: {

      update_current_page_finished_blocks (state, data) {
        state.current_page_finished_blocks = data
      },

      update_current_page_finished_blocks_detail (state, data) {
        //state.current_page_finished_blocks[data.id] = data.value
        Vue.set(state.current_page_finished_blocks, data.id, data.value)
      },

      update_page_finished (state, data) {
        state.page_finished = data
      },


      // RESET
      resetState (state) {
        Object.assign(state, getDefaultState())
      }

    },

    actions: {

      UPDATE_CURRENT_PAGE_FINISHED_BLOCKS({commit}, data) {
        commit('update_current_page_finished_blocks', data)
      },

      UPDATE_CURRENT_PAGE_FINISHED_BLOCKS_DETAIL({commit}, data) {
        commit('update_current_page_finished_blocks_detail', data)
      },

      UPDATE_PAGE_FINISHED({commit}, data) {
        commit('update_page_finished', data)
      },

      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_introduction