
const getDefaultState = () => {
    
    return {

      coach: {
        coach_id: '',
        coach_group: '',
      },    
      
      coach_tasks: {},

      coach_messages: {},

      coach_clients: {},

    }
}

const store_coach = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

      get_coach: state => state.coach,
      get_coach_tasks: state => state.coach_tasks,
      get_coach_messages: state => state.coach_messages,
      get_coach_clients: state => state.coach_clients,
        
    },

    mutations: {

      update_coach (state, data) {
        state.coach = data
      },

      update_coach_tasks (state, data) {
        state.coach_tasks = data
      },

      update_coach_messages (state, data) {
        state.coach_messages = data
      },

      update_coach_clients (state, data) {
        state.coach_clients = data
      },


      // RESET
      resetState (state) {
        Object.assign(state, getDefaultState())
      }

    },

    actions: {

      UPDATE_COACH({commit}, data) {
        commit('update_coach', data)
      },

      UPDATE_COACH_TASKS({commit}, data) {
        commit('update_coach_tasks', data)
      },

      UPDATE_COACH_MESSAGES({commit}, data) {
        commit('update_coach_messages', data)
      },

      UPDATE_COACH_CLIENTS({commit}, data) {
        commit('update_coach_clients', data)
      },


      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_coach