
const getDefaultState = () => {
    
    return {

      all_assessments_in_module: [],
      current_assessment_content: {},
      overall_current_position: 0,
      current_assessment_data: []

    }
}

const store_assessment = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

      get_all_assessments_in_module: state => state.all_assessments_in_module,
      get_current_assessment_content: state => state.current_assessment_content,
      get_current_assessment_data: state => state.current_assessment_data,
      get_overall_current_position: state => state.overall_current_position,

        
    },

    mutations: {

        update_all_assessments_in_module (state, data) {
        state.all_assessments_in_module = data
        },

        update_current_assessment_content (state, data) {
        state.current_assessment_content = data
        },

        update_current_assessment_data (state, data) {
        state.current_assessment_data = data
        },

        update_overall_current_position (state, data) {
        state.overall_current_position = data
        },

        update_current_assessment_data_field_value(state, data) {

            state.current_assessment_data[data.key] = data.value
        },




        // RESET
        resetState (state) {
            Object.assign(state, getDefaultState())
        }

    },

    actions: {

      UPDATE_ALL_ASSESSMENTS_IN_MODULE({commit}, data) {
        commit('update_all_assessments_in_module', data)
      },

      UPDATE_CURRENT_ASSESSMENT_CONTENT({commit}, data) {
        commit('update_current_assessment_content', data)
      },

      UPDATE_CURRENT_ASSESSMENT_DATA({commit}, data) {
        commit('update_current_assessment_data', data)
      },

      UPDATE_OVERALL_CURRENT_POSITION({commit}, data) {
        commit('update_overall_current_position', data)
      },

      UPDATE_CURRENT_ASSESSMENT_DATA_FIELD_VALUE({commit}, data) {
        commit('update_current_assessment_data_field_value', data)
      },






      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_assessment