import Vue from 'vue'
import Vuex from 'vuex'
import VueSession from 'vue-session';

import store_auth from '@/store/modules/store_auth'
import store_client from '@/store/modules/store_client'
import store_lection from '@/store/modules/store_lection'
import store_quiz from '@/store/modules/store_quiz'
import store_task from '@/store/modules/store_task'
import store_assessment from '@/store/modules/store_assessment'
import store_loader from '@/store/modules/store_loader'
import store_dialogs from '@/store/modules/store_dialogs'
import store_messages from '@/store/modules/store_messages'
import store_main_messages from '@/store/modules/store_main_messages'
import store_menu from '@/store/modules/store_menu'
import store_introduction from '@/store/modules/store_introduction'
import store_guided_task from '@/store/modules/store_guided_task'

import store_coach from '@/store/modules/store_coach'

import store_supervisor from '@/store/modules/store_supervisor'

Vue.use(Vuex)
Vue.use(VueSession)

export default new Vuex.Store({
  
  modules: {

    store_auth,
    store_menu,

    // client
    store_client,
    store_lection,
    store_quiz,
    store_task,
    store_assessment,
    store_loader,
    store_dialogs,
    store_messages,
    store_main_messages,
    store_introduction,
    store_guided_task,

    // coach
    store_coach,

    // supervisor
    store_supervisor,

  } 

})
