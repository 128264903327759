import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'

Vue.use(Router)

const router = new Router({

  mode: 'history',

  // scroll always to top
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return scrollTo
  },


  base: process.env.BASE_URL,

  routes: [

    {
      path: '/',
      name: 'start',
      component: () => import('@/views/_common/login.vue'),
      meta: {
        breadcrumb: [
          {text: 'Login', to: '/', exact: true, disabled: false}
        ],
        role: ['Client', 'Supervisor', 'Coach']
      }
    },
    { path: '*', 
      component: () => import('@/views/_common/404.vue'), 
      meta: {
        breadcrumb: [
          {text: '404', to: '/', exact: true, disabled: true}
        ],
        role: ['Client', 'Supervisor', 'Coach']
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/_common/login.vue'),
      meta: {
        breadcrumb: [
          {text: 'Login', to: '/login', exact: true, disabled: false}
        ],
        role: ['Client', 'Supervisor', 'Coach']
      }

    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import('@/views/_common/change_password.vue'),
      //beforeEnter: ifAuthenticated,
      meta: {
        breadcrumb: [
          {text: 'Neues Passwort', to: '/change-password', exact: true, disabled: false}
        ],
        role: ['Client', 'Supervisor', 'Coach']
      }

    },
    {
      path: '/datasecurity',
      name: 'datasecurity',
      component: () => import('@/views/_common/data_security.vue'),
      //beforeEnter: ifAuthenticated,
      meta: {
        breadcrumb: [
          {text: 'Startseite', to: '/client', exact: true, disabled: false},
          {text: 'Datenschutz', to: '/datasecurity', exact: true, disabled: true}
        ],
        role: ['Client', 'Supervisor', 'Coach']
      }

    },

    {
      path: '/client',
      name: 'client',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_home.vue'),
      //beforeEnter: ifAuthenticatedAsClient,
      meta: {
        breadcrumb: [
          {text: 'Startseite', to: '/client', exact: true, disabled: false}
        ],
        role: ['Client']
      },
      children: [
        {
          path: '/client/help',
          name: 'client-help',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_help.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Hilfe', to: '/client/help', exact: true, disabled: true}
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/impressum',
          name: 'client-impressum',
          component: () => import('@/views/_client/client_impressum.vue'),
          //beforeEnter: ifAuthenticated,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Impressum', to: '/impressum', exact: true, disabled: true}
            ],
            role: ['Client']
          }
    
        },
        {
          path: '/client/technical-help',
          name: 'client-technical-help',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_technical_help.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Technische Hilfe', to: '/client/technical-help', exact: true, disabled: true}
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/change-password',
          name: 'client-change-password',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_change_password.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Passwort ändern', to: '/client/change-password', exact: true, disabled: true}
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/emergency',
          name: 'client-emergency',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_emergency.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Mein Notfallkoffer', to: '/client/emergency', exact: true, disabled: true}
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/emergency/error',
          name: 'client-emergency-error',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_emergency_error.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Mein Notfallkoffer', to: '/client/emergency', exact: true, disabled: false},
              {text: 'Notfall', to: '/client/emergency/error', exact: true, disabled: true},
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/emergency/accent',
          name: 'client-emergency-accent',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_emergency_accent.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Mein Notfallkoffer', to: '/client/emergency', exact: true, disabled: false},
              {text: 'Meine eigenen Lösungsmöglichkeiten', to: '/client/emergency/accent', exact: true, disabled: true},
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/emergency/accent/emotion',
          name: 'client-emergency-accent-emotion',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_emergency_accent_emotion.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Mein Notfallkoffer', to: '/client/emergency', exact: true, disabled: false},
              {text: 'Meine eigenen Lösungsmöglichkeiten', to: '/client/emergency/accent', exact: true, disabled: false},
              {text: 'Ich fühle mich nicht gut', to: '/client/emergency/accent/emotion', exact: true, disabled: true},
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/emergency/accent/control',
          name: 'client-emergency-accent-control',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_emergency_accent_control.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Mein Notfallkoffer', to: '/client/emergency', exact: true, disabled: false},
              {text: 'Meine eigenen Lösungsmöglichkeiten', to: '/client/emergency/accent', exact: true, disabled: false},
              {text: 'Ich gewinne die Kontrolle', to: '/client/emergency/accent/control', exact: true, disabled: true},
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/emergency/accent/crime',
          name: 'client-emergency-accent-crime',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_emergency_accent_crime.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Mein Notfallkoffer', to: '/client/emergency', exact: true, disabled: false},
              {text: 'Meine eigenen Lösungsmöglichkeiten', to: '/client/emergency/accent', exact: true, disabled: false},
              {text: 'Ich bleibe straffrei', to: '/client/emergency/accent/crime', exact: true, disabled: true},
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/emergency/success',
          name: 'client-emergency-success',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_emergency_success.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Mein Notfallkoffer', to: '/client/emergency', exact: true, disabled: false},
              {text: 'Ansprechpartner', to: '/client/emergency/success', exact: true, disabled: true},
            ],
            role: ['Client']
          }
        },

        {
          path: '/client/introduction/:rank',
          name: 'client-introduction',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_introduction.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          props: { rank: null },
          meta: {

            // data is set by navigation guard
            breadcrumb: [],
            role: ['Client']

          },
        },
        {
          path: '/client/messages/:search_prop',
          name: 'client-messages',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_messages.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          props: { search_prop: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Chats', to: '/client/messages/null', exact: true, disabled: true}
            ],
            role: ['Client']
          },
        },
        {
          path: '/client/messages/archived/:search_prop',
          name: 'client-messages-archived',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_messages_archived.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          props: { search_prop: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Archivierte Chats', to: '/client/messages/archived', exact: true, disabled: true}
            ],
            role: ['Client']
          },
        },
        {
          path: '/client/messages/detailed/:thread_id',
          name: 'client-messages-detailed',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_messages_detailed.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          props: { thread_id: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Chats', to: '/client/messages/null', exact: true, disabled: false},
              {text: 'Chat Details', to: '/client/messages/detailed', exact: true, disabled: true}
            ],
            role: ['Client']
          },
        },
        {
          path: '/client/new-message',
          name: 'client-new-messages',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_messages_new.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Chats', to: '/client/messages/null', exact: true, disabled: false},
              {text: 'Neuer Chat', to: '/client/new-message', exact: true, disabled: true}
            ],
            role: ['Client']
          },
        },
        {
          path: '/client/tasks',
          name: 'client-tasks',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_tasks.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Meine Übungen', to: '/client/tasks', exact: true, disabled: true}
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/tasks/:task_id',
          name: 'client-tasks-details',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_tasks_details.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          props: { task_id: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Meine Übungen', to: '/client/tasks', exact: true, disabled: false},
              {text: 'Übung ansehen', to: '/client/tasks', exact: true, disabled: true}
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/polls',
          name: 'client-polls',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_open_polls.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Fragebögen', to: '/client/polls', exact: true, disabled: true}
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/polls/:module/:session/:lection/:timepoint/:step/:name',
          name: 'client-polls-single',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_assessment_single_questionnaire.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          props: { module: null, session: null, lection: null, timepoint: null, step: null, name: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Fragebögen', to: '/client/polls', exact: true, disabled: false},
              {text: 'Fragebogen ausfüllen', to: '/client/polls/single', exact: true, disabled: true},
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/bank',
          name: 'client-bank',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_bank.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Konto', to: '/client/bank', exact: true, disabled: true}
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/awards',
          name: 'client-awards',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_awards.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Auszeichnungen', to: '/client/awards', exact: true, disabled: true}
            ],
            role: ['Client']
          }
        },
        {
          path: '/client/overview',
          name: 'client-overview',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_overview.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Überblick', to: '/client/overview', exact: true, disabled: true}
            ],
            role: ['Client']
          },

        },
        {
          path: '/client/overview/:module/:session/:lection',
          name: 'client-intervention',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_intervention.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {

            // data is set by navigation guard
            breadcrumb: [],
            role: ['Client']
          },

        },
        {
          path: '/client/overview/assessment/:module/:session/:lection/:timepoint/:step',
          name: 'client-assessment',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_assessment.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Überblick', to: '/client/overview', exact: true, disabled: false},
              {text: 'Assessment', to: '/client/overview/assessment', exact: true, disabled: true}
            ],
            role: ['Client']
          }
        },

        {
          path: '/client/final',
          name: 'client-final',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_final_view.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Überblick', to: '/client/overview', exact: true, disabled: false},
              {text: 'Abschließende Hinweise', to: '/client/final', exact: true, disabled: true},
            ],
            role: ['Client']
          }
        },

        {
          path: '/client/has-to-wait',
          name: 'client-has-to-wait',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_has_to_wait.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Überblick', to: '/client/overview', exact: true, disabled: false},
              {text: 'Einen kurzen Augenblick', to: '/client/has-to-wait', exact: true, disabled: true},
            ],
            role: ['Client']
          }
        },

        {
          path: '/client/not-allowed',
          name: 'client-not-allowed',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_client/client_not_allowed.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/client', exact: true, disabled: false},
              {text: 'Überblick', to: '/client/overview', exact: true, disabled: false},
              {text: 'Nicht erlaubt', to: '/client/not-allowed', exact: true, disabled: true},
            ],
            role: ['Client']
          }
        },

      ]

    },

    {
      path: '/coach',
      name: 'coach',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/_coach/coach_home.vue'),
      //beforeEnter: ifAuthenticatedAsCoach,
      meta: {
        breadcrumb: [
          {text: 'Startseite', to: '/coach', exact: true, disabled: false}
        ],
        role: ['Coach']
      },
      children: [
        {
          path: '/coach/change-password',
          name: 'coach-change-password',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_coach/coach_change_password.vue'),
          //beforeEnter: ifAuthenticatedAsCoach,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/coach', exact: true, disabled: false},
              {text: 'Passwort ändern', to: '/coach/coach-password', exact: true, disabled: true}
            ],
            role: ['Coach']
          }
        },
        {
          path: '/coach/impressum',
          name: 'coach-impressum',
          component: () => import('@/views/_coach/coach_impressum.vue'),
          //beforeEnter: ifAuthenticated,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/coach', exact: true, disabled: false},
              {text: 'Impressum', to: '/coach/impressum', exact: true, disabled: true}
            ],
            role: ['Coach']
          }
    
        },
        {
          path: '/coach/messages/:search_prop',
          name: 'coach-messages',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_coach/coach_messages.vue'),
          props: { search_prop: null },
          //beforeEnter: ifAuthenticatedAsCoach,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/coach', exact: true, disabled: false},
              {text: 'Alle Chats', to: '/coach/messages', exact: true, disabled: true}
            ],
            role: ['Coach']
          }
        },
        {
          path: '/coach/messages/archived/:search_prop',
          name: 'coach-messages-archived',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_coach/coach_messages_archived.vue'),
          props: { search_prop: null },
          //beforeEnter: ifAuthenticatedAsCoach,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/coach', exact: true, disabled: false},
              {text: 'Archivierte Chats', to: '/coach/messages/archived', exact: true, disabled: true}
            ],
            role: ['Coach']
          }
        },
        {
          path: '/coach/messages/detailed/:thread_id',
          name: 'coach-messages-detailed',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_coach/coach_messages_detailed.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          props: { thread_id: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/coach', exact: true, disabled: false},
              {text: 'Chats', to: '/coach/messages/null', exact: true, disabled: false},
              {text: 'Chat Details', to: '/coach/messages/detailed', exact: true, disabled: true}
            ],
            role: ['Coach']
          },
        },
        {
          path: '/coach/new-message',
          name: 'coach-new-messages',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_coach/coach_messages_new.vue'),
          //beforeEnter: ifAuthenticatedAsCoach,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/coach', exact: true, disabled: false},
              {text: 'Alle Chats', to: '/coach/messages/null', exact: true, disabled: false},
              {text: 'Neuer Chat', to: '/coach/new-message', exact: true, disabled: true}
            ],
            role: ['Coach']
          },
        },
        {
          path: '/coach/tasks/:search_prop',
          name: 'coach-tasks',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_coach/coach_tasks.vue'),
          //beforeEnter: ifAuthenticatedAsCoach,
          props: { search_prop: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/coach', exact: true, disabled: false},
              {text: 'Offene Übungen', to: '/coach/tasks/null', exact: true, disabled: true}
            ],
            role: ['Coach']
          }
        },
        {
          path: '/coach/tasks/closed/:search_prop',
          name: 'coach-tasks-closed',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_coach/coach_tasks_closed.vue'),
          //beforeEnter: ifAuthenticatedAsCoach,
          props: { search_prop: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/coach', exact: true, disabled: false},
              {text: 'Abgeschlossene Übungen', to: '/coach/tasks/closed/null', exact: true, disabled: true}
            ],
            role: ['Coach']
          }
        },
        {
          path: '/coach/tasks/detailed/:task_id',
          name: 'coach-tasks-detailed',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_coach/coach_tasks_detailed.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          props: { task_id: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/coach', exact: true, disabled: false},
              {text: 'Offene Übungen', to: '/coach/tasks/null', exact: true, disabled: false},
              {text: 'Übung - Details', to: '/coach/tasks/detailed', exact: true, disabled: true}
            ],
            role: ['Coach']
          },
        },
      ]

    },

    {
      path: '/supervisor',
      name: 'supervisor',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_home.vue'),
      //beforeEnter: ifAuthenticatedAsSupervisor,
      meta: {
        breadcrumb: [
          {text: 'Startseite', to: '/supervisor', exact: true, disabled: false}
        ],
        role: ['Supervisor']
      },
      children: [
        {
          path: '/supervisor/change-password',
          name: 'supervisor-change-password',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_change_password.vue'),
          //beforeEnter: ifAuthenticatedAsSupervisor,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Passwort ändern', to: '/supervisor/change-password', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/impressum',
          name: 'supervisor-impressum',
          component: () => import('@/views/_supervisor/supervisor_impressum.vue'),
          //beforeEnter: ifAuthenticated,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Impressum', to: '/supervisor/impressum', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          }
    
        },
        {
          path: '/supervisor/risk/:search_prop',
          name: 'supervisor-risk',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_risk_assessment.vue'),
          //beforeEnter: ifAuthenticatedAsSupervisor,
          props: { search_prop: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Risk-Assessment', to: '/supervisor/risk', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/acute/:search_prop',
          name: 'supervisor-risk-acute',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_risk_assessment_acute.vue'),
          //beforeEnter: ifAuthenticatedAsSupervisor,
          props: { search_prop: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Risikoerfassung - Acute-2007-SR', to: '/supervisor/risk', exact: true, disabled: true},
            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/cte/:search_prop',
          name: 'supervisor-risk-cte',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_risk_assessment_cte.vue'),
          //beforeEnter: ifAuthenticatedAsSupervisor,
          props: { search_prop: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Risikoerfassung - CTE', to: '/supervisor/risk', exact: true, disabled: true}            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/cmc/:search_prop',
          name: 'supervisor-risk-cmc',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_risk_assessment_cmc.vue'),
          //beforeEnter: ifAuthenticatedAsSupervisor,
          props: { search_prop: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Risikoerfassung - CMC', to: '/supervisor/risk', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/flashlight-post/:search_prop',
          name: 'supervisor-risk-flashlight-post',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_risk_assessment_flashlight_post.vue'),
          //beforeEnter: ifAuthenticatedAsSupervisor,
          props: { search_prop: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Blitzlicht (Postmessung)', to: '/supervisor/risk', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/flashlight-pre/:search_prop',
          name: 'supervisor-risk-flashlight-pre',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_risk_assessment_flashlight_pre.vue'),
          //beforeEnter: ifAuthenticatedAsSupervisor,
          props: { search_prop: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Blitzlicht (Prämessung)', to: '/supervisor/risk', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/critical-event/:search_prop',
          name: 'supervisor-critical-event',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_critical_event.vue'),
          //beforeEnter: ifAuthenticatedAsSupervisor,
          props: { search_prop: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Kritische Ereignisse', to: '/supervisor/critical-event', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/payments/:search_prop',
          name: 'supervisor-payments',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_payments.vue'),
          //beforeEnter: ifAuthenticatedAsSupervisor,
          props: { search_prop: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Aufwandsentschädigung', to: '/supervisor/payments', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/help',
          name: 'supervisor-help',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_help.vue'),
          //beforeEnter: ifAuthenticatedAsSupervisor,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Hilfe', to: '/supervisor/help', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/assessment/:search_prop',
          name: 'supervisor-assessment',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_assessment.vue'),
          props: { search_prop: null },
          //beforeEnter: ifAuthenticatedAsSupervisor,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Fragebögen', to: '/supervisor/assessment', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/assessment-single/:module/:session/:lection/:timepoint/:step/:name/:client_user_id/:client_id',
          name: 'supervisor-assessment-single',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_assessment_single.vue'),
          //beforeEnter: ifAuthenticatedAsSupervisor,
          props: { module: null, session: null, lection: null, timepoint: null, step: null, name: null, client_user_id: null, client_id: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Fragebögen', to: '/supervisor/assessment', exact: true, disabled: false},
              {text: 'Fragebogen ausfüllen', to: '/supervisor/assessment-single', exact: true, disabled: true},
            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/messages/:search_prop',
          name: 'supervisor-messages',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_messages.vue'),
          props: { search_prop: null },
          //beforeEnter: ifAuthenticatedAsSupervisor,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Alle Chats', to: '/supervisor/messages', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/messages/archived/:search_prop',
          name: 'supervisor-messages-archived',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_messages_archived.vue'),
          props: { search_prop: null },
          //beforeEnter: ifAuthenticatedAsSupervisor,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Archivierte Chats', to: '/supervisor/messages/archived', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          }
        },
        {
          path: '/supervisor/messages/detailed/:thread_id',
          name: 'supervisor-messages-detailed',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_messages_detailed.vue'),
          //beforeEnter: ifAuthenticatedAsClient,
          props: { thread_id: null },
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Chats', to: '/supervisor/messages/null', exact: true, disabled: false},
              {text: 'Chat Details', to: '/supervisor/messages/detailed', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          },
        },
        {
          path: '/supervisor/new-message',
          name: 'supervisor-new-messages',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_messages_new.vue'),
          //beforeEnter: ifAuthenticatedAsSupervisor,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Alle Chats', to: '/supervisor/messages/null', exact: true, disabled: false},
              {text: 'Neuer Chat', to: '/supervisor/new-message', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          },
        },
        {
          path: '/supervisor/exclude-client/:client_id/:client_user_id',
          name: 'supervisor-exclude-client',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '@/views/_supervisor/supervisor_exclude_client.vue'),
          props: { client_id: null, client_user_id: null },
          //beforeEnter: ifAuthenticatedAsSupervisor,
          meta: {
            breadcrumb: [
              {text: 'Startseite', to: '/supervisor', exact: true, disabled: false},
              {text: 'Klient*in ausschließen', to: '/supervisor/exclude-client/:id', exact: true, disabled: true}
            ],
            role: ['Supervisor']
          },
        },
      ]

    }
  ]
})


/* 

Um die Vor- und Zurück-Buttons des Browsers zu deaktiveren:

https://stackoverflow.com/questions/51980296/detect-back-button-in-navigation-guards-of-vue-router

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
}) */

// beforeEach
router.beforeEach((to, from, next) => {


    if (from.name == null) {

      next()

    } else {

      // if authenticated and password_changed
      if (store.getters['store_auth/get_is_authenticated']) {

        const user_role = store.getters['store_auth/get_user_group']

        // if password already reset
        if (String(store.getters['store_auth/get_password_changed']) == 'true') {

          // check if meta.role allows to enter the route
          if (to.meta.role.includes(user_role)) {

            if (to.name == 'client-intervention') {

              to.meta.breadcrumb = [
                {text: 'Startseite', to: '/client', exact: true, disabled: false},
                {text: 'Überblick', to: '/client/overview', exact: true, disabled: false},
                {text: 'Modul ' + to.params.module + ' - Sitzung ' + to.params.session + ' - Lektion ' + to.params.lection, to: '/client/overview/' + to.params.module + '/' + to.params.session + '/' + to.params.lection, exact: true, disabled: true}
              ]
          
              next()
          
            } else if (to.name == 'client-introduction') {
          
              to.meta.breadcrumb = [
                {text: 'Startseite', to: '/client', exact: true, disabled: false},
                {text: 'Einführung - Seite ' + to.params.rank, to: '/client/introduction/' + to.params.rank, exact: true, disabled: true}
              ]
          
              next()
          
            } else if (to.name == 'coach-clients-details') {
          
              to.meta.breadcrumb = [
                {text: 'Startseite', to: '/coach', exact: true, disabled: false},
                {text: 'Alle Klienten*Klientinnen', to: '/coach/clients', exact: true, disabled: false},
                {text: to.params.client_username, to: '/coach/clients/' + to.params.client_id, exact: true, disabled: true}
              ]
          
              next()
          
            } else {
          
              next()
          
            }

          } else {

            store.dispatch('store_auth/CHANGE_LOGIN_INFO', { login_info_type: "error", login_info: 'Sie verfügen leider nicht über die Berechtigung die gewünschte Webseite zu besuchen. Bitte melden Sie sich als ' + to.meta.role[0] + ' an.'})
            next('/login')

          }


        // if password has not been changed
        } else {

          if (to.name == 'change-password') {

            next()

          } else {

            next('/change-password')

          }

        }

      // if not authenticated
      } else {

        // authentication not necessary for /login
        if (to.name == 'login') {

          next()

        } else {

          next('/login')

        }

      }

    }

  
})

export default router;
