const getDefaultState = () => {
    
    return {

        mini: true,
        drawer: true

    }

}

const store_menu = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

      get_mini: state => state.mini,
      get_drawer: state => state.drawer,

        
    },

    mutations: {

      update_mini (state, data) {
        state.mini = data
      },

      update_drawer (state, data) {
        state.drawer = data
      },


      // RESET
      resetState (state) {
        Object.assign(state, getDefaultState())
      }

    },

    actions: {

      UPDATE_MINI({commit}, data) {
        commit('update_mini', data)
      },

      UPDATE_DRAWER({commit}, data) {
        commit('update_drawer', data)
      },

      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_menu