import store from '@/store/store'
import authentication from '@/_auth/auth'
import router from '@/router'

//import isAfter from "date-fns/is_after";
//import subtractMinutes from "date-fns/sub_minutes";
//import differenceInMilliSeconds from "date-fns/difference_in_milliseconds";

export {initSession, timer};

var timer;

function initSession() {
    
    var countDownDate = new Date(store.getters['store_auth/get_expires_at'])
    
    store.dispatch('store_auth/UPDATE_TIMEOUT_TIMER_RUNNING', true)

    // Update the count down every 1 second
    timer = setInterval(() => {

        // Get today's date and time
        var now = new Date().getTime();
            
        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        if (distance < 300000) {
            store.dispatch('store_auth/UPDATE_SHOW_TIMEOUT_DIALOG', true) 
        }
        
            
        // If the count down is over, start logout routine
        if (distance < 0 && store.getters['store_auth/get_is_authenticated']) {
            clearInterval(timer);

            authentication.LogoutRoutine().then(() => {

                store.dispatch('store_auth/UPDATE_TIMEOUT_TIMER_RUNNING', false)
                store.dispatch('store_auth/UPDATE_SHOW_TIMEOUT_DIALOG', false)
                store.dispatch('store_auth/UPDATE_TIMEOUT_DIALOG_SHOWN', false)

                store.dispatch('store_auth/CHANGE_LOGIN_INFO', { 
                    login_info_type: "error", 
                    login_info: 'Aus Datenschutzgründen wurden Sie automatisch abgemeldet.'
                })


                router.push('/login')

            })
        }

        if (!store.getters['store_auth/get_is_authenticated']) {
            clearInterval(timer);

            store.dispatch('store_auth/UPDATE_TIMEOUT_TIMER_RUNNING', false)
            store.dispatch('store_auth/UPDATE_SHOW_TIMEOUT_DIALOG', false)
            store.dispatch('store_auth/UPDATE_TIMEOUT_DIALOG_SHOWN', false)
        }

    }, 1000);
}