
//import Vue from 'vue'

const getDefaultState = () => {
    
    return {

      client: {
        client_id: '',
        client_group: '',
      },    
      
      client_tasks: {
        unfinished_tasks_count: '',
      },

      client_messages: {
        unread_message_count: '',
      },

      client_questionnaires: {
        unfinished_questionnaires_count: '',
      },

      client_reward: {
          max_coins: '',
          max_euros: '',
          change_value: '',
          current_coins: '',
          current_euros: '',
          spended_coins: '',
          spended_euros: '',
      },

      client_award: {

        award_not_equipped: 0,

      },

      client_coach: {
          id: '',
          username: '',
          email: '',
          firstname: '',
          last_name: '',
      },

      client_supervisor: {
          id: '',
          username: '',
          email: '',
          firstname: '',
          last_name: '',
          telephone: '',
          institute_town: '',
          institute_street: '',
          institute_street_number: '',
          institute_postal_code: '',
      },

      client_progress: {
          current_module_nr: '',
          current_session_nr: '',
          current_lession_nr: '',
          intervention_started: '',
          intervention_finished: '',
          introduction_finished: ''

      },

      client_intervention_overview: [],

      client_emergency_data: {

        // emotion
        m3_s4_l4_t1: {},
        m3_s1_l7_t1: {},
        m2_s3_l5_t3: {},

        // control
        m6_s1_l5_t1: {},
        m6_s1_l5_t2: {},
        m6_s2_l4_t1: {},
        m6_s3_l4_t2: {},
        m6_s4_l5_t1: {},

        // crime
        m1_s4_l4_t2: {},
        helpful_thought_1: {},
        helpful_thought_2: {},
        helpful_thought_3: {}

      },

      money_has_payed: false,
      amount_money: 0,
      page_allowed: false,

    }
}

const store_client = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

      get_client: state => state.client,
      get_client_coach: state => state.client_coach,
      get_client_supervisor: state => state.client_supervisor,
      get_client_progress: state => state.client_progress,
      get_client_reward: state => state.client_reward,
      get_client_award: state => state.client_award,
      get_client_tasks: state => state.client_tasks,
      get_client_messages: state => state.client_messages,
      get_client_questionnaires: state => state.client_questionnaires,
      get_client_intervention_overview: state => state.client_intervention_overview,
      get_client_emergency_data: state => state.client_emergency_data,
      get_money_has_payed: state => state.money_has_payed,
      get_amount_money: state => state.amount_money,
      get_page_allowed: state => state.page_allowed
        
    },

    mutations: {

      update_client (state, data) {
        state.client = data
      },

      update_client_coach (state, data) {
        state.client_coach = data
      },

      update_client_supervisor (state, data) {
        state.client_supervisor = data
      },

      update_client_progress (state, data) {
        state.client_progress = data
      },

      update_client_reward (state, data) {
        state.client_reward = data
      },

      update_client_award (state, data) {
        state.client_award = data
      },

      update_client_reward_current_coins (state, data) {
        state.client_reward.current_coins = data
      },

      update_client_reward_spended_coins (state, data) {
        state.client_reward.spended_coins = data
      },

      update_client_reward_current_euros (state, data) {
        state.client_reward.current_euros = data
      },

      update_client_reward_spended_euros (state, data) {
        state.client_reward.spended_euros = data
      },

      update_client_tasks (state, data) {
        state.client_tasks = data
      },

      update_client_messages (state, data) {
        state.client_messages = data
      },

      update_client_questionnaires (state, data) {
        state.client_questionnaires = data
      },

      update_client_intervention_overview (state, data) {
        state.client_intervention_overview = data
      },

      update_client_emergency_data (state, data) {
        state.client_emergency_data = data
      },

      update_money_has_payed (state, data) {
        state.money_has_payed = data
      },

      update_amount_money (state, data) {
        state.amount_money = data
      },

      update_new_award_flag (state) {
        state.client_progress.new_award_flag = false
      },

      update_page_allowed (state, data) {
        console.log('update_page_allowed')
        state.page_allowed = data
      },


      // RESET
      resetState (state) {
        Object.assign(state, getDefaultState())
      }

    },

    actions: {

      UPDATE_CLIENT({commit}, data) {
        commit('update_client', data)
      },

      UPDATE_CLIENT_COACH({commit}, data) {
        commit('update_client_coach', data)
      },

      UPDATE_CLIENT_SUPERVISOR({commit}, data) {
        commit('update_client_supervisor', data)
      },

      UPDATE_CLIENT_PROGRESS({commit}, data) {
        commit('update_client_progress', data)
      },

      UPDATE_CLIENT_REWARD({commit}, data) {
        commit('update_client_reward', data)
      },

      UPDATE_CLIENT_AWARD({commit}, data) {
        commit('update_client_award', data)
      },

      UPDATE_NEW_AWARD_FLAG({commit}, data) {
        commit('update_new_award_flag', data)
      },

      UPDATE_CLIENT_REWARD_CURRENT_COINS({commit}, data) {
        commit('update_client_reward_current_coins', data)
      },

      UPDATE_CLIENT_REWARD_CURRENT_EUROS({commit}, data) {
        commit('update_client_reward_current_euros', data)
      },

      UPDATE_CLIENT_REWARD_SPENDED_COINS({commit}, data) {
        commit('update_client_reward_spended_coins', data)
      },

      UPDATE_CLIENT_REWARD_SPENDED_EUROS({commit}, data) {
        commit('update_client_reward_spended_euros', data)
      },

      UPDATE_CLIENT_TASKS({commit}, data) {
        commit('update_client_tasks', data)
      },

      UPDATE_CLIENT_MESSAGES({commit}, data) {
        commit('update_client_messages', data)
      },

      UPDATE_CLIENT_QUESTIONNAIRES({commit}, data) {
        commit('update_client_questionnaires', data)
      },

      UPDATE_CLIENT_INTERVENTION_OVERVIEW({commit}, data) {
        commit('update_client_intervention_overview', data)
      },

      UPDATE_CLIENT_EMERGENCY_DATA({commit}, data) {
        commit('update_client_emergency_data', data)
      },

      UPDATE_MONEY_HAS_PAYED({commit}, data) {
        commit('update_money_has_payed', data)
      },

      UPDATE_AMOUNT_MONEY({commit}, data) {
        commit('update_amount_money', data)
      },

      UPDATE_PAGE_ALLOWED({commit}, data) {
        commit('update_page_allowed', data)
      },


      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_client