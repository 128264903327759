<template>

    <div class="mt-3" v-if="authenticated && show_msg_by_path" color="grey lighten-3">
        <v-alert
            :icon="msg_icon"
            border="left"
            :color="msg_color"
            v-model="msg_show_tmp"
            v-if="msg_show"
            dismissible
            colored-border
            elevation="2"
        > 
            <div v-if="new_messages == 1 && usergroup == 'Client'">Sie haben eine neue <router-link :to="{ name: 'client-messages', params: { search_prop: 'null'} }">Nachricht</router-link>.</div>
            <div v-if="new_messages > 1 && usergroup == 'Client'">Sie haben {{ new_messages }} neue <router-link :to="{ name: 'client-messages', params: { search_prop: 'null'} }">Nachrichten</router-link>.</div>

            <div v-if="new_messages == 1 && usergroup == 'Coach'">Sie haben eine neue <router-link :to="{ name: 'coach-messages', params: { search_prop: 'null'} }">Nachricht</router-link>.</div>
            <div v-if="new_messages > 1 && usergroup == 'Coach'">Sie haben {{ new_messages }} neue <router-link :to="{ name: 'coach-messages', params: { search_prop: 'null'} }">Nachrichten</router-link>.</div>

            <div v-if="new_messages == 1 && usergroup == 'Supervisor'">Sie haben eine neue <router-link :to="{ name: 'supervisor-messages', params: { search_prop: 'null'} }">Nachricht</router-link>.</div>
            <div v-if="new_messages > 1 && usergroup == 'Supervisor'">Sie haben {{ new_messages }} neue <router-link :to="{ name: 'supervisor-messages', params: { search_prop: 'null'} }">Nachrichten</router-link>.</div>

        </v-alert>

        <v-alert
            :icon="msg_task_icon"
            border="left"
            :color="msg_task_color"
            v-model="msg_show_task_tmp"
            v-if="msg_task_show"
            dismissible
            colored-border
            elevation="2"
        > 
            <div v-if="unfinished_tasks_count == 1 && usergroup == 'Client'">Sie haben eine nicht bearbeitete <router-link :to="{ name: 'client-tasks', params: { search_prop: 'null'} }">Übung</router-link>.</div>
            <div v-if="unfinished_tasks_count > 1 && usergroup == 'Client'">Sie haben {{ unfinished_tasks_count }} nicht bearbeitete <router-link :to="{ name: 'client-tasks', params: { search_prop: 'null'} }">Übungen</router-link>.</div>

            <div v-if="unfinished_tasks_count == 1 && usergroup == 'Coach'">Sie haben eine nicht bearbeitete <router-link :to="{ name: 'coach-tasks', params: { search_prop: 'null'} }">Übung</router-link>.</div>
            <div v-if="unfinished_tasks_count > 1 && usergroup == 'Coach'">Sie haben {{ unfinished_tasks_count }} nicht bearbeitete <router-link :to="{ name: 'coach-tasks', params: { search_prop: 'null'} }">Übungen</router-link>.</div>

        </v-alert>

        <v-alert
            :icon="msg_q_icon"
            border="left"
            :color="msg_q_color"
            v-model="msg_show_q_tmp"
            v-if="msg_q_show"
            dismissible
            colored-border
            elevation="2"
        > 
            
            <div v-if="unfinished_questionnaires_count == 1 && usergroup == 'Client'">Sie haben einen nicht bearbeiteten <router-link :to="{ name: 'client-polls', params: { search_prop: 'null'} }">Fragebogen</router-link>.</div>
            <div v-if="unfinished_questionnaires_count > 1 && usergroup == 'Client'">Sie haben {{ unfinished_questionnaires_count }} nicht bearbeitete <router-link :to="{ name: 'client-polls', params: { search_prop: 'null'} }">Fragebögen</router-link>.</div>

            <div v-if="unfinished_questionnaires_count == 1 && usergroup == 'Supervisor'">Sie haben einen nicht bearbeiteten <router-link :to="{ name: 'supervisor-assessment', params: { search_prop: 'null'} }">Fragebogen</router-link>.</div>
            <div v-if="unfinished_questionnaires_count > 1 && usergroup == 'Supervisor'">Sie haben {{ unfinished_questionnaires_count }} nicht bearbeitete <router-link :to="{ name: 'supervisor-assessment', params: { search_prop: 'null'} }">Fragebögen</router-link>.</div>


        </v-alert>

        <v-alert
            :icon="msg_ce_icon"
            border="left"
            :color="msg_ce_color"
            v-model="msg_show_ce_tmp"
            v-if="msg_ce_show"
            dismissible
            colored-border
            elevation="2"
        > 

            <div v-if="critical_events == 1 && usergroup == 'Supervisor'">Sie haben eine ungelesenes, potentiell kritisches <router-link :to="{ name: 'supervisor-critical-event', params: { search_prop: 'null'} }">Ereignis</router-link>.</div>
            <div v-if="critical_events > 1 && usergroup == 'Supervisor'">Sie haben {{ unfinished_questionnaires_count }} ungelesene, potentiell kritische <router-link :to="{ name: 'supervisor-critical-event', params: { search_prop: 'null'} }">Ereignisse</router-link>.</div>

        </v-alert>

        <v-divider v-if="show_divider" />

    </div>
    
</template>

<script>
export default {

    name: 'main-messages',

    data: () => ({
        
        msg_show_tmp: true,
        msg_show_task_tmp: true,
        msg_show_q_tmp: true,
        msg_show_ce_tmp: true,

    }),

    created() {

    },

    computed: {

        new_messages() {

            if (this.usergroup == 'Client') {

                return this.$store.getters['store_client/get_client_messages'].unread_message_count

            } else if (this.usergroup == 'Supervisor') {

                return this.$store.getters['store_supervisor/get_supervisor_messages'].unread_message_count

            } else {

                return this.$store.getters['store_coach/get_coach_messages'].unread_message_count

            }

        },

        unfinished_tasks_count() {

            if (this.usergroup == 'Client') {

                return this.$store.getters['store_client/get_client_tasks'].unfinished_tasks_count

            } else if (this.usergroup == 'Coach') {

                return this.$store.getters['store_coach/get_coach_tasks'].unfinished_tasks_count

            } else {
                return 0
            }

        },

        unfinished_questionnaires_count() {

            if (this.usergroup == 'Client') {

                return this.$store.getters['store_client/get_client_questionnaires'].unfinished_questionnaires_count

            } else if (this.usergroup == 'Supervisor') {

                return this.$store.getters['store_supervisor/get_supervisor_questionnaires'].unfinished_questionnaires_count

            } else {
                return 0
            }

        },

        critical_events() {

            if (this.usergroup == 'Supervisor') {

                return this.$store.getters['store_supervisor/get_supervisor_critical_events'].unread_critical_event_count

            } else {
                return 0
            }

        },

        show_divider() {

            if (this.usergroup == 'Client') {

                if ((this.msg_show_q_tmp && this.msg_q_show) || (this.msg_show_task_tmp && this.msg_task_show) || (this.msg_show_tmp && this.msg_show)) {
                    return true
                } else {
                    return false
                }

            } else if (this.usergroup == 'Supervisor') {

                if ((this.msg_show_q_tmp && this.msg_q_show) || (this.msg_show_task_tmp && this.msg_task_show) || (this.msg_show_tmp && this.msg_show) || (this.msg_show_ce_tmp && this.msg_ce_show)) {
                    return true
                } else {
                    return false
                }

            } else {

                if ((this.msg_show_task_tmp && this.msg_task_show) || (this.msg_show_tmp && this.msg_show) ) {
                    return true
                } else {
                    return false
                }

            }

        },

        authenticated() {
            return this.$store.getters['store_auth/get_is_authenticated']
        },

        usergroup() {
            return this.$store.getters['store_auth/get_user_group']
        },

        msg_txt () {
            return this.$store.getters['store_main_messages/get_msg_txt']
        },
        msg_icon () {
            return this.$store.getters['store_main_messages/get_msg_icon']
        },
        msg_color () {
            return this.$store.getters['store_main_messages/get_msg_color']
        },
        msg_show () {
            return this.$store.getters['store_main_messages/get_msg_show']
        },

        show_msg_by_path() {

            if (this.$route.name == 'client-assessment' || this.$route.name == 'client-polls-single') {
                return false
            } else {
                return true
            }
        },

        msg_task_txt () {
            return this.$store.getters['store_main_messages/get_msg_task_txt']
        },
        msg_task_icon () {
            return this.$store.getters['store_main_messages/get_msg_task_icon']
        },
        msg_task_color () {
            return this.$store.getters['store_main_messages/get_msg_task_color']
        },
        msg_task_show () {
            return this.$store.getters['store_main_messages/get_msg_task_show']
        },

        msg_q_txt () {
            return this.$store.getters['store_main_messages/get_msg_q_txt']
        },
        msg_q_icon () {
            return this.$store.getters['store_main_messages/get_msg_q_icon']
        },
        msg_q_color () {
            return this.$store.getters['store_main_messages/get_msg_q_color']
        },
        msg_q_show () {
            return this.$store.getters['store_main_messages/get_msg_q_show']
        },

        msg_ce_txt () {
            return this.$store.getters['store_main_messages/get_msg_ce_txt']
        },
        msg_ce_icon () {
            return this.$store.getters['store_main_messages/get_msg_ce_icon']
        },
        msg_ce_color () {
            return this.$store.getters['store_main_messages/get_msg_ce_color']
        },
        msg_ce_show () {
            return this.$store.getters['store_main_messages/get_msg_ce_show']
        },

    },

    methods: {


        goToQuestionnaires() {

            this.msg_show__task_tmp = false
            
            if (this.usergroup == 'Client') {

                this.$router.push('/client/polls')

            } else if (this.usergroup == 'Coach') {

                this.$router.push('/coach')

            } else {

                this.$router.push('/supervisor/polls')
            }

        }


    }
}
</script>

<style scoped>

</style>