
const getDefaultState = () => {
    
    return {

        msg_txt: '',
        msg_icon: 'fas fa-exclamation-circle',
        msg_color: 'error',
        msg_show: false, 

        msg_task_txt: '',
        msg_task_icon: 'fas fa-exclamation-circle',
        msg_task_color: 'error',
        msg_task_show: false, 

        msg_q_txt: '',
        msg_q_icon: 'fas fa-exclamation-circle',
        msg_q_color: 'error',
        msg_q_show: false, 

        msg_ce_txt: '',
        msg_ce_icon: 'fas fa-exclamation-circle',
        msg_ce_color: 'error',
        msg_ce_show: false, 

        msg_award_txt: '',
        msg_award_icon: 'fas fa-exclamation-circle',
        msg_award_color: 'error',
        msg_award_show: false, 


    }
}

const store_main_messages = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

      get_msg_txt: state => state.msg_txt,
      get_msg_color: state => state.msg_color,
      get_msg_icon: state => state.msg_icon,
      get_msg_show: state => state.msg_show,

      get_msg_task_txt: state => state.msg_task_txt,
      get_msg_task_color: state => state.msg_task_color,
      get_msg_task_icon: state => state.msg_task_icon,
      get_msg_task_show: state => state.msg_task_show,

      get_msg_q_txt: state => state.msg_q_txt,
      get_msg_q_color: state => state.msg_q_color,
      get_msg_q_icon: state => state.msg_q_icon,
      get_msg_q_show: state => state.msg_q_show,

      get_msg_ce_txt: state => state.msg_ce_txt,
      get_msg_ce_color: state => state.msg_ce_color,
      get_msg_ce_icon: state => state.msg_ce_icon,
      get_msg_ce_show: state => state.msg_ce_show,

      get_msg_award_txt: state => state.msg_award_txt,
      get_msg_award_color: state => state.msg_award_color,
      get_msg_award_icon: state => state.msg_award_icon,
      get_msg_award_show: state => state.msg_award_show

    },

    mutations: {

      update_msg_txt(state, data) {
        state.msg_txt = data
      },

      update_msg_color(state, data) {
        state.msg_color = data
      },

      update_msg_icon(state, data) {
        state.msg_icon = data
      },

      update_msg_show(state, data) {
        state.msg_show = data
      },

      update_msg_task_txt(state, data) {
        state.msg_task_txt = data
      },

      update_msg_task_color(state, data) {
        state.msg_task_color = data
      },

      update_msg_task_icon(state, data) {
        state.msg_task_icon = data
      },

      update_msg_task_show(state, data) {
        state.msg_task_show = data
      },

      update_msg_q_txt(state, data) {
        state.msg_q_txt = data
      },

      update_msg_q_color(state, data) {
        state.msg_q_color = data
      },

      update_msg_q_icon(state, data) {
        state.msg_q_icon = data
      },

      update_msg_q_show(state, data) {
        state.msg_q_show = data
      },

      update_msg_ce_txt(state, data) {
        state.msg_ce_txt = data
      },

      update_msg_ce_color(state, data) {
        state.msg_ce_color = data
      },

      update_msg_ce_icon(state, data) {
        state.msg_ce_icon = data
      },

      update_msg_ce_show(state, data) {
        state.msg_ce_show = data
      },

      update_msg_award_txt(state, data) {
        state.msg_award_txt = data
      },

      update_msg_award_color(state, data) {
        state.msg_award_color = data
      },

      update_msg_award_icon(state, data) {
        state.msg_award_icon = data
      },

      update_msg_award_show(state, data) {
        state.msg_award_show = data
      },


      // RESET
      resetState (state) {
        Object.assign(state, getDefaultState())
      }

    },

    actions: {

      UPDATE_MSG_TXT({commit}, data) {
        commit('update_msg_txt', data)
      },

      UPDATE_MSG_COLOR({commit}, data) {
        commit('update_msg_color', data)
      },

      UPDATE_MSG_ICON({commit}, data) {
        commit('update_msg_icon', data)
      },

      UPDATE_MSG_SHOW({commit}, data) {
        commit('update_msg_show', data)
      },

      UPDATE_MSG_TASK_TXT({commit}, data) {
        commit('update_msg_task_txt', data)
      },

      UPDATE_MSG_TASK_COLOR({commit}, data) {
        commit('update_msg_task_color', data)
      },

      UPDATE_MSG_TASK_ICON({commit}, data) {
        commit('update_msg_task_icon', data)
      },

      UPDATE_MSG_TASK_SHOW({commit}, data) {
        commit('update_msg_task_show', data)
      },

      UPDATE_MSG_Q_TXT({commit}, data) {
        commit('update_msg_q_txt', data)
      },

      UPDATE_MSG_Q_COLOR({commit}, data) {
        commit('update_msg_q_color', data)
      },

      UPDATE_MSG_Q_ICON({commit}, data) {
        commit('update_msg_q_icon', data)
      },

      UPDATE_MSG_Q_SHOW({commit}, data) {
        commit('update_msg_q_show', data)
      },

      UPDATE_MSG_CE_TXT({commit}, data) {
        commit('update_msg_ce_txt', data)
      },

      UPDATE_MSG_CE_COLOR({commit}, data) {
        commit('update_msg_ce_color', data)
      },

      UPDATE_MSG_CE_ICON({commit}, data) {
        commit('update_msg_ce_icon', data)
      },

      UPDATE_MSG_CE_SHOW({commit}, data) {
        commit('update_msg_ce_show', data)
      },

      UPDATE_MSG_AWARD_TXT({commit}, data) {
        commit('update_msg_award_txt', data)
      },

      UPDATE_MSG_AWARD_COLOR({commit}, data) {
        commit('update_msg_award_color', data)
      },

      UPDATE_MSG_AWARD_ICON({commit}, data) {
        commit('update_msg_award_icon', data)
      },

      UPDATE_MSG_AWARD_SHOW({commit}, data) {
        commit('update_msg_award_show', data)
      },


      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_main_messages