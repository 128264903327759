<template>
  <v-app class="app">

    <v-navigation-drawer
        v-model="drawer"   
        app
        clipped
        color="grey lighten-5"
        v-if="show_navigation_drawer"
      >

        <MainMenu :class="$vuetify.breakpoint.mdAndDown ? 'mb-5 pb-5' : ''" />
      
      </v-navigation-drawer>

    <v-app-bar 
      app 
      extended 
      permanent
      clipped-left
      extension-height="0"
    >

      <v-app-bar-nav-icon :disabled="!nav_bar_disabled" @click.stop="drawer = !drawer"/>

      <v-spacer></v-spacer>

      <!-- Logout Timer -->

      <v-tooltip bottom v-if="authenticated">

        <template v-slot:activator="{ on, attrs }">

          <v-card flat color="transparent" class="py-0 px-1" v-on="on" v-bind="attrs">

            <v-card-subtitle class="caption disabled px-1 pt-1 pb-0 text-left">Timeout</v-card-subtitle>
            <v-card-title class="caption px-1 pb-0 pt-0 primary--text">{{ logout_time }} Uhr</v-card-title>
            
          </v-card>
        </template>

        <span>
            Aus Datenschutzgründen werden Sie um {{ logout_time }} Uhr automatisch abgemeldet.
        </span>

      </v-tooltip>
      
      <!-- USER Login/Logout -->
      <LoginLogout />

      <Loader slot="extension"/>

    </v-app-bar>

    <!-- CONTENT -->

    <v-content class="mb-4">

      <v-container fluid class="main-container">

        <!-- BREADCRUMBS -->
        <Breadcrumbs v-if="show_breadcrumbs" />

        <!-- MAIN MESSAGES -->
        <MainMessages />

        <!-- ROUTER-VIEW -->
        <router-view v-if="show_content"/>

        <!-- FOOTER -->
        <v-divider ></v-divider>

        <Footer />

      </v-container>
    </v-content>

    <!-- Go to top -->
    <BackToTopFab />

    <!-- Global Snack Bar -->
    <v-snackbar
      v-model="global_snackbar"
      :timeout="timeout"
    >
      {{ get_global_snackbar_text }}

      <v-btn
        outlined 
        fab 
        x-small 
        color="accent"
        left
        @click="close_global_snackbar"
      >
        <v-icon color="accent">fas fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <!-- timeout warning dialog  -->
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
    >
      <v-card outlined class="mx-auto">

        <v-toolbar flat color="transparent">
            <v-icon large color="error">fas fa-exclamation-triangle</v-icon>
            <v-toolbar-title class="headline ml-4 primary--text">Hinweis: Automatische Abmeldung</v-toolbar-title>
        </v-toolbar>

        <v-divider color="error"></v-divider>

        <v-card-text class="mt-5">
          <p>Um größtmöglichen Datenschutz zu gewährleisten, werden Sie spätestens nach drei Stunden automatisch abgemeldet.</p>
          <p class="title">Sie werden um {{ logout_time }} abgemeldet.</p>
          <p>Bitte beenden Sie Ihre aktuellen Arbeiten. Sie können sich danach umgehend wieder anmelden.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="my-4">
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            rounded 
            outlined
            @click="dialog_shown"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import LoginLogout from '@/components/_layout/header/login_logout.vue'
import MainMenu from '@/components/_layout/header/main_menu.vue'
import Footer from '@/components/_layout/footer/footer.vue'
import Loader from '@/components/_common/loader.vue'
import BackToTopFab from '@/components/_common/fab_back_to_top'
import Breadcrumbs from '@/components/_layout/breadcrumbs'

import MainMessages from '@/components/_common/main_messages'

import {initSession} from "@/_auth/session_manager"

export default {
  name: 'App',
  data: () => ({

    timeout: 0,

    drawer: null,
    

  }),

  created() {

    if (!this.$store['store_auth/get_timeout_timer_running'] && this.authenticated) {
      initSession()
    }

  },


  methods: {

    close_global_snackbar() {
      this.$store.dispatch('store_dialogs/UPDATE_SHOW_GLOBAL_SNACKBAR', false)
    },

    dialog_shown() {
      this.$store.dispatch('store_auth/UPDATE_TIMEOUT_DIALOG_SHOWN', true)
      this.$session.set('timeout_dialog_shown', true)
    }

  },

  computed: {

    dialog() {

      if (this.authenticated) {
        if (this.$store.getters['store_auth/get_timeout_dialog_shown'] && this.$store.getters['store_auth/get_logout_countdown']) {
          return false
        } else {
          return this.$store.getters['store_auth/get_show_timeout_dialog']
        }
      } else {
        return false
      }

      
      
    },

/*     timeout_class() {

      var time = this.$store.getters['store_auth/get_logout_countdown']

      if (time.hours == 0 && time.minutes < 5 && this.authenticated) {

        return 'caption px-1 pb-0 pt-0 error--text'

      } else {
        return 'caption px-1 pb-0 pt-0 primary--text'
      }
      
    }, */

/*     logout_countdown() {


        var time = this.$store.getters['store_auth/get_logout_countdown']
        var hours
        var minutes
        var seconds

        if (time.hours < 10) {
            hours = '0' + time.hours
        } else {
          hours = time.hours
        }

        if (time.minutes < 10) {
            minutes = '0' + time.minutes
        } else {
          minutes = time.minutes
        }

        if (time.seconds < 10) {
            seconds = '0' + time.seconds
        } else {
          seconds = time.seconds
        }

        return hours + ':' + minutes + ':' + seconds

    }, */

    logout_time() {

      var countDownDate = new Date(this.$store.getters['store_auth/get_expires_at'])

      if (countDownDate.getMinutes() < 10) {
        var minutes = '0' + countDownDate.getMinutes()
      } else {
        minutes = countDownDate.getMinutes()
      }

      if (countDownDate.getSeconds() < 10) {
        var seconds = '0' + countDownDate.getSeconds()
      } else {
        seconds = countDownDate.getSeconds()
      }

      return countDownDate.getHours() + ':' + minutes + ':' + seconds

    },

/*     logout_countdown_dialog() {


        var time = this.$store.getters['store_auth/get_logout_countdown']

        if (time.minutes == 0) {
            return time.seconds + ' Sekunden'
        } else {
          return time.minutes + ' Minuten und ' + time.seconds + ' Sekunden'
        }

    }, */

    nav_bar_disabled() {

      if (this.authenticated) {

        if (this.$route.name == 'change-password') {

          return false

        } else {

          return true

        }

      } else {

        return false

      }


    },

    show_navigation_drawer() {

      if (this.authenticated) {

        if (this.$route.name == 'change-password') {

          return false

        } else {

          return true

        }

      } else {

        return false

      }


    },

    show_breadcrumbs() {

      if (this.$route.name == 'login' || this.$route.name == 'change-password' || this.$route.path == "/") {

        return false

      } else {

        return true

      }

    },

/*     drawer: {

      set(val) {

        this.$store.dispatch('store_menu/UPDATE_DRAWER', val)

      },

      get() {

        return this.$store.getters['store_menu/get_drawer']
      }

    }, */

/*     mini: {

      set(val) {

        this.$store.dispatch('store_menu/UPDATE_MINI', val)

      },

      get() {

        return this.$store.getters['store_menu/get_mini']
      }

    }, */

    authenticated() {
      return this.$store.getters['store_auth/get_is_authenticated']
    },

    isClient() {
      if (this.$store.getters['store_auth/get_user_group'] == 'Client') {
        return true
      } else {
        return false
      }
    },

    show_content() {

        return true

      },


    global_snackbar() {
      return this.$store.getters['store_dialogs/get_show_global_snackbar']
    },

    get_global_snackbar_text() {
      return this.$store.getters['store_dialogs/get_global_snackbar_text']
    }
    
  },

  components: {
    LoginLogout,
    MainMenu,
    Footer,
    Loader,
    BackToTopFab,
    Breadcrumbs,

    MainMessages,

  },

};
</script>

<style>
.app {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.main-container {
  margin-left: auto  !important;
  margin-right: auto  !important;
  max-width: 1470px  !important;
}

.v-toolbar__extension {
  padding: 0px !important;
}

.v-breadcrumbs {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 10px !important;
  padding-right: 0px !important;
}

p {
  margin: 6px !important;
  -moz-hyphens: auto;
  hyphens: auto;
}

.v-navigation-drawer {
  transition: none !important;
}

.lightbox {
  box-shadow: 0 0 20px inset rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, transparent 72px);
}
</style>


