import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import de from 'vuetify/es5/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: false,
      },
    themes: {
      light: {
        "primary": "#004b8c",
        "secondary": "#424242",
        "accent": "#fcb900",
        "error": "#FF5252",
        "info": "#2196F3",
        "success": "#4CAF50",
        "warning": "#FFC107"
      },
    },
  },
    lang: {
      locales: { de },
      current: 'de',
    },
  icons: {
    iconfont: 'fa',
  },
});
