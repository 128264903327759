<template>
    <div ml-0 pl-0>

        <v-breadcrumbs :items="breadcrumbList" ml-0 pl-0></v-breadcrumbs>

        <v-divider class="mb-0"></v-divider>

  </div>
</template>

<script>
export default {
    name: 'breadcrumbs',

    data: () => ({

    }),

    watch: {

    },

    computed: {

        breadcrumbList() {

            if (this.$route.path != "/" || this.$route.path != "/login" ) {

                var output = this.$route.meta.breadcrumb

                return output

            } else {

                return []

            }

        },


        show_breadcrumb() {

            if (!this.$store.getters['store_loader/get_show_main_loader']) {

                if (this.$route.path == "/" || this.$route.path == "/login" ) {

                    return false

                } else {

                    return true

                }

            } else {

                return false

            }

        },

    }
    
}
</script>

<style scoped>

</style>


