<template>
    <!-- USER Login/Logout -->
    
        <v-menu offset-y origin="center center" :nudge-bottom="5" :nudge-left="30" transition="slide-y-transition">

            <template v-slot:activator="{ on }">
                    <div class="wrapper">
                        <!-- <span class="custom_badge" v-if="show_menu_badge">!</span> -->


                        <v-btn 
                            icon 
                            color="primary" 
                            v-on="on"
                            :disabled="!nav_bar_disabled"
                        >
                            <v-icon medium v-if="!nav_bar_disabled">fas fa-user-times</v-icon>
                            <v-icon medium v-if="nav_bar_disabled">fas fa-user-check</v-icon>
                        </v-btn>

                    </div>

                
            </template>

            <v-card>
                <v-list v-if="authenticated" :width="270">
                    <v-list-item>
                        <v-list-item-avatar>
                            
                            <v-avatar color="accent">
                                <span class="white--text headline pl-0">{{initials}}</span>
                            </v-avatar>

                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{ username }}</v-list-item-title>
                            <v-list-item-subtitle>{{ get_usergroup }}</v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>


                </v-list>

                <v-divider />

                <v-list v-if="authenticated">
                    <v-list-item @click="routerLinkToPasswordChange">
                        <v-list-item-icon>
                            <v-icon>fas fa-key</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Passwort ändern</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-list>

                <v-divider></v-divider>

                <v-list>
                    <v-list-item v-if="!authenticated" @click="routerLinkToLogin">
                        <v-list-item-icon>
                            <v-icon>fas fa-sign-in-alt</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Login</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-else @click="routerLinkToLogout">
                        <v-list-item-icon>
                            <v-icon>fas fa-sign-out-alt</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>

        </v-menu>

</template>

<script>
import authentication from '@/_auth/auth'

export default {
    name: 'LoginLogout',

    data: () => ({

        fav: true,
        menu: false,
        
    }),

    computed: {

        nav_bar_disabled() {

            if (this.authenticated) {

                if (this.$route.name == 'change-password') {

                return false

                } else {

                return true

                }

            } else {

                return false

            }

        },

        get_usergroup() {

            if (this.usergroup == 'Client') {
                return 'Klient'
            } else if (this.usergroup == 'Coach') {
                return 'Coach'
            } else {
                return 'Bewährungshelfer'
            }
        },

       
        authenticated() {
            return this.$store.getters['store_auth/get_is_authenticated']
        },

        username() {
            return this.$store.getters['store_auth/get_user_username']
        },

        usergroup() {
            return this.$store.getters['store_auth/get_user_group']
        },

        initials() {

            if (this.usergroup == 'Client') {
                return 'Kl'
            } else if (this.usergroup == 'Coach') {
                return 'Co'
            } else {
                return 'Be'
            }

        }

        
    },

    methods: {

        routerLinkToPasswordChange() {
            if (this.usergroup == 'Client') {
                this.$router.push('/client/change-password')
            } else if (this.usergroup == 'Coach') {
                this.$router.push('/coach/change-password')
            } else {
                this.$router.push('/supervisor/change-password')
            }
        },

        // link to login
        routerLinkToLogin () {
            this.$router.push('/login')
        },

        // handle logout + clear the store
        routerLinkToLogout () {

            authentication.LogoutRoutine().then(() => {

                this.$router.push('/login')

                var all = document.querySelectorAll('.v-overlay')

                all[0].remove()

            })
        },

        // link to messages
        routerLinkToMessages () {

            if (this.usergroup == 'Client') {
                this.$router.push('/client/messages')
            } else if (this.usergroup == 'Coach') {
                this.$router.push('/coach/messages')
            } else {
                this.$router.push('/supervisor/messages')
            }
            
        },

        // link to bank
        routerLinkToBank () {
            this.$router.push('/client/bank')
        },

        // link to tasks
        routerLinkToTasks () {
            this.$router.push('/client/tasks')
        },

        // link to awards
        routerLinkToAwards () {
            this.$router.push('/client/awards')
        },

        // link to help
        routerLinkToHelp () {
            this.$router.push('/client/help')
        },

        medkitClicked() {
            this.$store.dispatch('store_dialogs/UPDATE_SHOW_EMERGENCY_DIALOG', true)
        },
    }
    
}
</script>


<style scoped>

.wrapper {
  position:relative;
}

.custom_badge {
    position: absolute;
    top: 2px;
    left:-12px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    text-align: center;
    background: #FF5252;
    color: #fefefe;
}

</style>


