
const getDefaultState = () => {
    
    return {

        correct_answers_in_current_quiz: 0

    }
}

const store_quiz = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

      get_correct_answers_in_current_quiz: state => state.correct_answers_in_current_quiz,

    },

    mutations: {

      update_correct_answers_in_current_quiz (state, data) {
        state.correct_answers_in_current_quiz = data
      },


      // RESET
      resetState (state) {
        Object.assign(state, getDefaultState())
      }

    },

    actions: {

      UPDATE_CORRECT_ANSWERS_IN_CURRENT_QUIZ({commit}, data) {
        commit('update_correct_answers_in_current_quiz', data)
      },


      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_quiz