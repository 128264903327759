<template>

  <v-progress-linear
    :active="loading"
    color="primary"
    :indeterminate="true"
    class="ma-0"
    height="2"
    style="top: 0px;"
  ></v-progress-linear>

</template>

<script>
export default {
    name: 'loader',

    data: () => ({
      
    }),

    computed: {
        loading() {
            return this.$store.getters['store_loader/get_show_main_loader']
        }
    },

}
</script>
