import Vue from 'vue'
import VueSession from 'vue-session';

Vue.use(VueSession);

const getDefaultState = () => {
    
    return {

      login_info: 'Bitte geben Sie ihr Passwort und ihren Benutzernamen ein.',
      login_info_type: 'info',

      redirect_url: '/',

      access_token: Vue.prototype.$session.get('access_token') || '',
      expires_in: Vue.prototype.$session.get('expires_in') || '',
      expires_at: Vue.prototype.$session.get('expires_at') || '',

      auth_status: '',

      // user
      user_group: Vue.prototype.$session.get('user_group') || '',
      user_username: Vue.prototype.$session.get('user_username') || '',

      // cookies
      cookies_accepted: Vue.prototype.$session.get('cookies_accepted') || false,

      password_changed: Vue.prototype.$session.get('password_changed') || false,

      // countdown till logout
      logout_countdown: {
          hours: 0,
          minutes: 0,
          seconds: 0 
      },

      timeout_timer_running: false,
      show_timeout_dialog: false,
      timeout_dialog_shown: Vue.prototype.$session.get('timeout_dialog_shown') || false,

        
    }
}

const store_auth = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

      get_login_info: state => state.login_info,
      get_login_info_type: state => state.login_info_type,

      get_redirect_url: state => state.redirect_url,

      get_password_changed: state => state.password_changed,

      get_is_authenticated: state => {

        if (state.access_token) {
          return new Date().getTime() < state.expires_at
        } else {
          return false
        }

      },

      get_auth_status: state => state.auth_status,

      get_user_group: state => state.user_group,

      get_user_username: state => state.user_username,

      get_cookies_accepted: state => state.cookies_accepted,

      get_expires_at: state => state.expires_at,
      get_expires_in: state => state.expires_in,

      get_logout_countdown: state => state.logout_countdown,
      get_show_timeout_dialog: state => state.show_timeout_dialog,
      get_timeout_dialog_shown: state => state.timeout_dialog_shown,
      get_timeout_timer_running: state => state.timeout_timer_running,

        
    },

    mutations: {

      update_redirect_url (state, data) {
        state.redirect_url = data
      },

      update_logout_countdown (state, data) {
        state.logout_countdown = data
      },

      update_show_timeout_dialog (state, data) {
        state.show_timeout_dialog = data
      },

      update_timeout_dialog_shown (state, data) {
        state.timeout_dialog_shown = data
      },

      update_timeout_timer_running (state, data) {
        state.timeout_timer_running = data
      },

      update_access_token (state, data) {
        state.access_token = data
      },

      update_cookies_accepted (state, data) {
        state.cookies_accepted = data
      },

      update_expires_in (state, data) {
        state.expires_in = data
      },

      update_expires_at (state, data) {
        state.expired_at = data
      },

      update_user_username (state, data) {
        state.user_username = data
      },

      update_password_changed (state, data) {
        state.password_changed = data
      },

      update_user_group (state, data) {
        state.user_group = data
      },

      author_request: (state) => {
        state.auth_status = 'loading'
      },

      author_success: (state, data) => {
        state.auth_status = 'success'
        state.access_token = data.access_token
        state.expires_in = data.expires_in
        state.user_username = data.user_username
        state.user_group = data.user_group
        state.expires_at = data.expires_at
        state.cookies_accepted = data.cookies_accepted
        state.password_changed = data.password_changed
      },

      author_error: (state) => {
        state.auth_status = 'error'
      },

      change_login_info: (state, data) => {
        state.login_info = data.login_info
        state.login_info_type = data.login_info_type
      },


      // RESET
      resetState (state) {
        Object.assign(state, getDefaultState())
      }

    },

    actions: {

      CHANGE_LOGIN_INFO({commit}, data) {
        commit('change_login_info', data)
      },

      UPDATE_LOGOUT_COUNTDOWN({commit}, data) {
        commit('update_logout_countdown', data)
      },

      UPDATE_SHOW_TIMEOUT_DIALOG({commit}, data) {
        commit('update_show_timeout_dialog', data)
      },

      UPDATE_TIMEOUT_DIALOG_SHOWN({commit}, data) {
        commit('update_timeout_dialog_shown', data)
      },

      UPDATE_TIMEOUT_TIMER_RUNNING({commit}, data) {
        commit('update_timeout_timer_running', data)
      },

      UPDATE_REDIRECT_URL({commit}, data) {
        commit('update_redirect_url', data)
      },

      UPDATE_PASSWORD_CHANGED({commit}, data) {
        commit('update_password_changed', data)
      },

      UPDATE_COOKIES_ACCEPTED({commit}, data) {
        commit('update_cookies_accepted', data)
      },

      UPDATE_EXPIRES_AT({commit}, data) {
        commit('update_expires_at', data)
      },

      UPDATE_EXPIRES_IN({commit}, data) {
        commit('update_expires_in', data)
      },

      UPDATE_USER_USERNAME({commit}, data) {
        commit('update_user_username', data)
      },

      UPDATE_USER_GROUP({commit}, data) {
        commit('update_user_group', data)
      },

      AUTHOR_REQUEST({commit}) {
        commit('author_request')

      },

      AUTHOR_SUCCESS({commit}, data) {
        commit('author_success', data)

      },

      AUTHOR_ERROR({commit}) {
        commit('author_error')

      },



      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_auth