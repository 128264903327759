const getDefaultState = () => {
    
    return {

        show_main_loader: false,

    }

}

const store_loader = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

      get_show_main_loader: state => state.show_main_loader,

        
    },

    mutations: {

      update_show_main_loader (state, data) {
        state.show_main_loader = data
      },


      // RESET
      resetState (state) {
        Object.assign(state, getDefaultState())
      }

    },

    actions: {

      UPDATE_SHOW_MAIN_LOADER({commit}, data) {
        commit('update_show_main_loader', data)
      },

      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_loader