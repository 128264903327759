const getDefaultState = () => {
    
    return {

      supervisor: {
        supervisor_id: '',
        supervisor_group: '',
      },    
      
      supervisor_questionnaires: {},

      supervisor_messages: {},

      supervisor_clients: {},

      critical_events: {},

      supervisor_risk_assessment: {},

      risk_data: {},

      open_payments: false,

    }
}

const store_supervisor = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

      get_supervisor: state => state.supervisor,
      get_supervisor_questionnaires: state => state.supervisor_questionnaires,
      get_supervisor_messages: state => state.supervisor_messages,
      get_supervisor_clients: state => state.supervisor_clients,
      get_supervisor_critical_events: state => state.critical_events,
      get_supervisor_risk_assessment: state => state.supervisor_risk_assessment,
      get_risk_data: state => state.risk_data,
      get_open_payments: state => state.open_payments,
        
    },

    mutations: {

      update_supervisor (state, data) {
        state.supervisor = data
      },

      update_open_payments (state, data) {
        state.open_payments = data
      },

      update_supervisor_questionnaires (state, data) {
        state.supervisor_questionnaires = data
      },

      update_supervisor_messages (state, data) {
        state.supervisor_messages = data
      },

      update_supervisor_critical_events (state, data) {
        state.critical_events = data
      },

      update_supervisor_risk_assessment (state, data) {
        state.supervisor_risk_assessment = data
      },

      update_supervisor_clients (state, data) {
        state.supervisor_clients = data
      },

      update_risk_data (state, data) {
        state.risk_data = data
      },


      // RESET
      resetState (state) {
        Object.assign(state, getDefaultState())
      }

    },

    actions: {

      UPDATE_SUPERVISOR({commit}, data) {
        commit('update_supervisor', data)
      },

      UPDATE_OPEN_PAYMENTS({commit}, data) {
        commit('update_open_payments', data)
      },

      UPDATE_SUPERVISOR_QUESTIONNAIRES({commit}, data) {
        commit('update_supervisor_questionnaires', data)
      },

      UPDATE_SUPERVISOR_MESSAGES({commit}, data) {
        commit('update_supervisor_messages', data)
      },

      UPDATE_SUPERVISOR_CRITICAL_EVENTS({commit}, data) {
        commit('update_supervisor_critical_events', data)
      },

      UPDATE_SUPERVISOR_RISK_ASSESSMENT({commit}, data) {
        commit('update_supervisor_risk_assessment', data)
      },

      UPDATE_SUPERVISOR_CLIENTS({commit}, data) {
        commit('update_supervisor_clients', data)
      },

      UPDATE_RISK_DATA({commit}, data) {
        commit('update_risk_data', data)
      },


      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_supervisor