<template>
  <v-fab-transition>
    <v-btn 
        fab
        small 
        dark
        fixed
        bottom
        right 
        color="primary" 
        v-scroll="onScroll" 
        v-show="fab" 
        @click="toTop"
        >
          <v-icon small>fas fa-chevron-up</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: 'fab-back-to-top',
  data: () => ({
    fab: false
  }),
  methods: {
    onScroll () {
      if (typeof window === 'undefined') return;

      const top = window.pageYOffset || document.documentElement.offsetTop || 0;
      
      this.fab = top > 100;
    },
    toTop () {
      //this.$router.push({ hash: '' });
      this.$vuetify.goTo(0);
    }
  }
};
</script>