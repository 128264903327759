import _ from 'lodash'

const getDefaultState = () => {
    
    return {

        finished: {},

    }
}

const store_task = {

    namespaced: true,

    state: getDefaultState(),

    getters: {

      get_finished: state => state.finished,

      get_finished_by_identifier: state => (taskID) => {

        return state.finished[taskID].finished

      },

    },

    mutations: {

      update_finished (state, data) {
        state.finished = data
      },

      add_finished_by_identifier (state, data) {
        //state.finished[data.identifier] = _.set(state.finished[data.identifier], data.property_name, data.data)
        state.finished = Object.assign({}, state.finished, { [data.identifier]: {[data.property_name]: data.data }})
      },

      define_finished_identifier(state, data) {
          state.finished = _.set(state.finished, data.taskID, {'finished': false})
      },


      // RESET
      resetState (state) {
        Object.assign(state, getDefaultState())
      }

    },

    actions: {

      UPDATE_FINISHED({commit}, data) {
        commit('update_finished', data)
      },

      ADD_FINISHED_BY_IDENTIFIER({commit}, data) {
        commit('add_finished_by_identifier', data)
      },

      DEFINE_FINISHED_IDENTIFIER({commit}, data) {
        commit('define_finished_identifier', data)
      },


      // RESET
      RESET_STATE ({ commit }) {
        commit('resetState')
      },  

    },

  }

export default store_task