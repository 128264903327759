<template>

    <v-footer color="transparent">
        
        <v-row
            justify="center"
            class="mt-4"
        >

            <v-col class="text-center mt-0 pt-0" cols="12" sm="12">

                <div class="caption my-3" v-if="$route.path == '/login' || $route.path == '/'">Sind Sie <b>Teilnehmer*in</b> oder <i>Bewährungshelfer*in</i> und haben ihr Passwort oder Ihren Benutzernamen vergessen? Bitte kontaktieren Sie Ihre*n <b>Bewährungshelfer*in</b> oder <i>die Studienleitung</i>.</div>

                <div v-else>
                    
                    <p class="caption" v-if="$store.getters['store_auth/get_user_group'] == 'Client'">Haben Sie technische Probleme oder funktioniert etwas nicht? <router-link :to="{ name: 'client-technical-help' }">Bitte klicken Sie hier</router-link>.</p>
                    <p class="caption" v-else>Haben Sie technische Probleme oder funktioniert etwas nicht? Schreiben Sie an den <a href="mailto:myTabu@med.uni-goettingen.de?subject=Anfrage an TECHNISCHEN SUPPORT">Technischen Support</a>.</p>

                </div>

            </v-col>

            <v-col class="text-center mt-0 pt-0 overline" cols="12" sm="12" v-if="authenticated">
                <span v-if="$store.getters['store_auth/get_user_group'] == 'Client'"><router-link to='/client/impressum'>Impressum</router-link></span>
                <span v-if="$store.getters['store_auth/get_user_group'] == 'Coach'"><router-link to='/coach/impressum'>Impressum</router-link></span>
                <span v-if="$store.getters['store_auth/get_user_group'] == 'Supervisor'"><router-link to='/supervisor/impressum'>Impressum</router-link></span>
            </v-col>

        </v-row>

    </v-footer>
</template>

<script>
export default {
    name: 'Footer',

    computed: {

        currentYear() {
            // Return today's date and time
            var currentTime = new Date()
            return currentTime.getFullYear()

            
        },

        authenticated() {
            return this.$store.getters['store_auth/get_is_authenticated']
        },
    }
}
</script>

