import axios from 'axios'
//import Vue from 'vue'

import Vue from 'vue'

import store from '@/store/store'

import {initSession, timer} from "@/_auth/session_manager";


/**
 * AUTHORIZATION
 */


const LoginRoutine = user => new Promise ((resolve, reject) => {
   /**
   * Summary. Basic authentication. Tries to get a JWT-Token
   * 
   * @param username      The username which is used for authentification
   * @param password  	The password which is used for authentification.
   */

  store.dispatch('store_auth/AUTHOR_REQUEST')

  const payload = "username=" + user.username + "&password=" + user.password
  const endpoint = 'auth/token/'

  axios.post(endpoint,  payload)

    .then(resp => {

      if (resp.data.error) {

        store.dispatch('store_auth/CHANGE_LOGIN_INFO', { login_info_type: "error", login_info: 'Ihr Benutzername und/oder ihr Passwort sind leider nicht richtig.'})

        Vue.prototype.$session.destroy()

        // remove the axios default header
        delete axios.defaults.headers.common['Authorization']

        // stop loader
        store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

      } else {

        Vue.prototype.$session.start()

        // set cookies
        Vue.prototype.$session.set('access_token', resp.data.access_token)
        Vue.prototype.$session.set('expires_in', resp.data.expires_in)

        const expires_at = resp.data.expires_in * 1000 + (new Date().getTime() - 60000)
        Vue.prototype.$session.set('expires_at', expires_at)
        Vue.prototype.$session.set('user_username', resp.data.user.username)
        Vue.prototype.$session.set('user_group', resp.data.user.group)
        Vue.prototype.$session.set('password_changed', resp.data.user.password_changed)
        Vue.prototype.$session.set('cookies_accepted', resp.data.user.cookies_accepted)
        Vue.prototype.$session.set('cookies_accepted', resp.data.user.cookies_accepted)
        Vue.prototype.$session.set('timeout_dialog_shown', false)

        // set store
        store.dispatch('store_auth/UPDATE_REDIRECT_URL', resp.data.user.redirect_url)

        const data = {

          'access_token': resp.data.access_token,
          'expires_in': resp.data.expires_in,
          'expires_at': expires_at,
          'user_username': resp.data.user.username,
          'user_group': resp.data.user.group,
          'cookies_accepted': resp.data.user.cookies_accepted,
          'password_changed': resp.data.user.password_changed

        }
        store.dispatch('store_auth/AUTHOR_SUCCESS', data)
        //this.user = resp.data.data.user


        // set header for axios
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.data.access_token

        // stop timer
        clearInterval(timer);
        store.dispatch('store_auth/UPDATE_TIMEOUT_TIMER_RUNNING', false)

        var current_countdown = {
            hours: 0,
            minutes: 0,
            seconds: 0 
        }

        store.dispatch('store_auth/UPDATE_LOGOUT_COUNTDOWN', current_countdown)

        // start logout timer
        initSession()
        
        resolve(resp)

      }

    })

  .catch(err => {

    store.dispatch('store_auth/AUTHOR_ERROR')

    if (err == 'Error: Network Error') {

      store.dispatch('store_auth/CHANGE_LOGIN_INFO', { login_info_type: "error", login_info: 'Leider ist der Server aktuell nicht erreichbar. Bitte versuchen Sie es in einigen Minuten erneut.'})

    } else {

      store.dispatch('store_auth/CHANGE_LOGIN_INFO', { login_info_type: "error", login_info: 'Ihr Benutzername und/oder ihr Passwort sind leider nicht richtig.'})

    }

    Vue.prototype.$session.destroy()

    // remove the axios default header
    delete axios.defaults.headers.common['Authorization']

    // stop loader
    store.dispatch('store_loader/UPDATE_SHOW_MAIN_LOADER', false)

    reject(err)

  })
})


/**
 * CHANGE PASSWORD
 */

const ChangePassword = (data) => new Promise ((resolve, reject) => {

  const payload = "old_password=" + data.old_password + "&new_password=" + data.new_password + "&new_password_validated=" + data.new_password_validated
  var endpoint = 'auth/change-password/'

  axios.patch(endpoint, payload)

  .then(resp => {

      Vue.prototype.$session.set('password_changed', true)
     
      resolve(resp)

  })

  .catch(err => {

      //error_handling(err)

      reject(err)
  
  })
})


/**
 * UPDATE COOKIES
 */


const UpdateCookies = () => new Promise ((resolve, reject) => {

  var endpoint = 'auth/update-cookies/'

  axios.patch(endpoint)

  .then(resp => {
     
      resolve(resp)

  })

  .catch(err => {

      //error_handling(err)

      reject(err)
  
  })
})

/**
 * LOGOUT ROUTINE
 */
function LogoutRoutine() {
  
  return new Promise((resolve, reject) => {


    const payload = "token=" + store.getters['store_auth/get_access_token']
    const endpoint = 'auth/revoke/'

    axios.post(endpoint,  payload)

      .then(resp => {

        // stop timer
        clearInterval(timer);
        store.dispatch('store_auth/UPDATE_TIMEOUT_TIMER_RUNNING', false)
        store.dispatch('store_auth/UPDATE_TIMEOUT_DIALOG_SHOWN', false)
        store.dispatch('store_auth/UPDATE_SHOW_TIMEOUT_DIALOG', false)

        var current_countdown = {
            hours: 0,
            minutes: 0,
            seconds: 0 
        }

        store.dispatch('store_auth/UPDATE_LOGOUT_COUNTDOWN', current_countdown)

        // remove cookies
        Vue.prototype.$session.destroy()

        // remove the axios default header
        delete axios.defaults.headers.common['Authorization']

        // set store
        store.dispatch('store_auth/UPDATE_REDIRECT_URL', '/')
        store.dispatch('store_auth/AUTHOR_SUCCESS', '')

        // clear stores
        store.dispatch('store_assessment/RESET_STATE')
        store.dispatch('store_client/RESET_STATE')
        store.dispatch('store_coach/RESET_STATE')
        store.dispatch('store_dialogs/RESET_STATE')
        store.dispatch('store_guided_task/RESET_STATE')
        store.dispatch('store_introduction/RESET_STATE')
        store.dispatch('store_lection/RESET_STATE')
        store.dispatch('store_loader/RESET_STATE')
        store.dispatch('store_main_messages/RESET_STATE')
        store.dispatch('store_menu/RESET_STATE')
        store.dispatch('store_messages/RESET_STATE')
        store.dispatch('store_quiz/RESET_STATE')
        store.dispatch('store_supervisor/RESET_STATE')
        store.dispatch('store_task/RESET_STATE')

        store.dispatch('store_auth/CHANGE_LOGIN_INFO', { 
          login_info_type: "info", 
          login_info: 'Bitte geben Sie ihr Passwort und ihren Benutzernamen ein.'
        })
        

        
        resolve(resp)

      })

    .catch(err => {

      reject(err)

    })

  })
}

/**
 * UPDATE FROM COOKIE -> checks every axios or router if cookie exists or not
 */

function update_from_cookies() {

  let access_token = Vue.prototype.$session.get("access_token");
  if (access_token && JSON.parse(access_token)) {
      store.dispatch('store_auth/UPDATE_ACCESS_TOKEN', access_token);
  } else {
    store.dispatch('store_auth/UPDATE_ACCESS_TOKEN', '');
  }

  let cookies_accepted = Vue.prototype.$session.get("cookies_accepted");
  if (cookies_accepted && JSON.parse(cookies_accepted)) {
    store.dispatch('store_auth/UPDATE_COOKIES_ACCEPTED', cookies_accepted);
  } else {
    store.dispatch('store_auth/UPDATE_COOKIES_ACCEPTED', '');
  }

  let expires_in = Vue.prototype.$session.get("expires_in");
  if (expires_in && JSON.parse(expires_in)) {
    store.dispatch('store_auth/UPDATE_EXPIRES_IN', expires_in);
  } else {
    store.dispatch('store_auth/UPDATE_EXPIRES_IN', '');
  }

  let expires_at= Vue.prototype.$session.get("expires_at");
  if (expires_at && JSON.parse(expires_at)) {
    store.dispatch('store_auth/UPDATE_EXPIRES_AT', expires_at);
  } else {
    store.dispatch('store_auth/UPDATE_EXPIRES_AT', '');
  }

  let password_changed = Vue.prototype.$session.get("password_changed");
  if (password_changed && JSON.parse(password_changed)) {
    store.dispatch('store_auth/UPDATE_PASSWORD_CHANGED', password_changed);
  } else {
    store.dispatch('store_auth/UPDATE_PASSWORD_CHANGED', '');
  }

  let user_group = Vue.prototype.$session.get("user_group");
  if (user_group && JSON.parse(user_group)) {
    store.dispatch('store_auth/UPDATE_USER_GROUP', user_group);
  } else {
    store.dispatch('store_auth/UPDATE_USER_GROUP', '');
  }

  let user_username = Vue.prototype.$session.get("user_username");
  if (user_username && JSON.parse(user_username)) {
    store.dispatch('store_auth/UPDATE_USER_USERNAME', user_username);
  } else {
    store.dispatch('store_auth/UPDATE_USER_USERNAME', '');
  }

  let timeout_dialog_shown = Vue.prototype.$session.get("timeout_dialog_shown");
  if (timeout_dialog_shown && JSON.parse(timeout_dialog_shown)) {
    store.dispatch('store_auth/UPDATE_TIMEOUT_DIALOG_SHOWN', timeout_dialog_shown);
  } else {
    store.dispatch('store_auth/UPDATE_TIMEOUT_DIALOG_SHOWN', '');
  }



}

export default {
  LoginRoutine,
  ChangePassword,
  LogoutRoutine,
  UpdateCookies,
  update_from_cookies
}
